import moment from "moment-timezone";
export const londonTimeZone = "+00:00"; //moment(new Date()).tz("Europe/London").format("Z");
// export const londonTimeZone = moment(new Date()).tz("Europe/London").format("Z");
// export const londonTimeZone = moment.tz(new Date(), "Europe/London").format("Z");
export const localTimeZone = moment().local().format("Z");
const originalDateTime = "2023-11-28T20:00:00" + localTimeZone;

//
const localDateTime = moment(originalDateTime).local();

console.log("* * * isoToDate FieldDate londonTimeZone Time:", {
  londonTimeZone: londonTimeZone,
});
console.log(
  "* * * isoToDate FieldDate Local Time:",
  { localTimeZone },
  { aa: originalDateTime.replace(londonTimeZone, localTimeZone) }
);
console.log(
  "* * * isoToDate FieldDate Local Time 2:",
  { localTimeZone },
  { aa: originalDateTime.replace(localTimeZone, londonTimeZone) }
);

// const originalDateTime = '2023-11-28T20:00:00+03:30';

// console.log('FieldDate:', londonDateTime);
export const changeTimeZone = (date) => {
  return moment(date?.replace(localTimeZone, londonTimeZone));
};

export const setUKZone = (date) => {
  let formattedDate = moment(date).format("YYYY-MM-DDTHH:mm:ssZ");
  // let formattedDate = moment.tz(date, "Europe/London").format("YYYY-MM-DDTHH:mm:ssZ");
  let final = formattedDate?.replace(localTimeZone, londonTimeZone);
  console.log("* * * isoToDate setUKZone:", {
    localTimeZone,
    formattedDate,
    date,
    final,
  });
  // return formattedDate?.replace(localTimeZone,'+00:00')
  return final;
  //  moment(date).utcOffset('+00:00', true).format();
};
// export const setToUKZone = (date) => {
// 	let formattedDate = moment(date).format("YYYY-MM-DDTHH:mm:ssZ");
// 	console.log("* * * isoToDate setToUKZone:", { localTimeZone, formattedDate, date }, formattedDate?.replace(localTimeZone, londonTimeZone));
// 	// return formattedDate?.replace(localTimeZone,'+00:00')
// 	return formattedDate?.replace(localTimeZone, londonTimeZone);
// 	//  moment(date).utcOffset('+00:00', true).format();
// };
export const setLocalZone = (date) => {
  try {
    let d = moment(date);

    let formattedDate = d.format("YYYY-MM-DDTHH:mm:ssZ");
    if (!d.isValid()) return;
    // let newDate = toUKTimezone(date).format("YYYY-MM-DDTHH:mm:ssZ");
    // let newDate = moment(date).tz("Europe/London").format("YYYY-MM-DDTHH:mm:ssZ");
    // let newDate = changeTimeZone(date).format("YYYY-MM-DDTHH:mm:ssZ");
    let newDate = moment
      .utc(date)
      .utcOffset(londonTimeZone)
      .format("YYYY-MM-DDTHH:mm:ssZ");

    let final = newDate?.replace(londonTimeZone, localTimeZone);

    console.log("* * * isoToDate setLocalZone:", {
      newDate,
      formattedDate,
      date,
      aa: d?.isValid(),
      localTimeZone,
      final,
    });
    return new Date(final);
    // return new Date(newDate?.replace(londonTimeZone, localTimeZone));
    // return new Date(toUKTimezone(date))
  } catch (error) {
    return null;
  }
};
export const convertToLondonTimeZone = (date) => {
  const londonTime = moment.tz(date, "Europe/London");

  const localTime = londonTime.clone().local();

  return {
    londonTime: londonTime.format(),
    localTime: localTime.format(),
  };
};

// export const setLocalZone = (date) => {
// 	const dateWithoutZone = moment.tz(date, "Europe/London").format("YYYY-MM-DDTHH:mm:ss.SSS");
// 	const localZone = moment(dateWithoutZone).format("Z");
// 	const dateWithLocalZone = [dateWithoutZone, localZone].join("");

// 	return new Date(dateWithLocalZone);
// };

// export const toUKTimezone = (date) => moment(date).tz("Europe/London");
export const toUKTimezone = (date) => {
  let d = moment(date);
  try {
    // if (d.isValid()) return moment.utc(date).utcOffset(londonTimeZone);
    if (d.isValid()) return d.tz("Europe/London");
    return null;
  } catch (error) {
    return null;
  }
};

function isoToDate(createdAt) {
  return toUKTimezone(createdAt)?.format("MM.DD.YYYY, HH:mm:ss");
}
export default isoToDate;
