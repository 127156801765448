import { ERRORS } from "enumerations";
import regex from "enumerations/regex";
import * as yup from "yup";

export const fieldNames = {
	name: "name",
	code: "code",
	amount: "amount",
	isActive: "isActive",
	discountType: "discountType",
};

export const validation = (URL_ID) =>
	yup.object().shape({
		[fieldNames.name]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.name)).label(fieldNames.name),
		[fieldNames.code]: yup.string().label(fieldNames.code),
		[fieldNames.amount]: yup.string().label(fieldNames.amount),
		[fieldNames.discountType]: yup.mixed().required(ERRORS.pleaseEnterYour(fieldNames.discountType)).label(fieldNames.discountType),
		[fieldNames.isActive]: yup.object().label(fieldNames.isActive),
	});
