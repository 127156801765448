import CheckedComponent from "components/CheckedComponent";
import TableAction from "components/TableAction";
import TableStatus from "components/TableStatus";
import { PLACEHOLDER_IMAGE } from "enumerations";
import routes from "operations/routing/routes";
import { Link } from "react-router-dom";
import { changePriceFormat, numberWithCommas } from "utils/calculators";

import isoToDate from "utils/isoToDate";
export const tableData = ({ onClick, newRoute, ...props }) =>

  props?.isBranch ?
    [
      {
        header: "hotel",
        Component: ({ param }) => {
          const { base, edit } = routes.hotel;
          return param.hotel ? (
            <span to={`${base + edit}/${param.hotel.id}`} target="_blank">
              {" "}
              {param.hotel?.name}{" "}
            </span>
          ) : (
            "Unknown"
          );
        },
      },
      {
        header: "orderCount",
        Component: ({ param }) => numberWithCommas(param.orderCount),
      },
      {
        header: "totalAmount",
        Component: ({ param }) => "£ " + changePriceFormat(param.totalAmount, 2),
      },
      {
        header: "createdAt",
        Component: ({ param }) => isoToDate(param.createdAt),
      },

    ] : [
      {
        header: "hotel",
        Component: ({ param }) => {
          const { base, edit } = routes.hotel;
          return param.hotel ? (
            <Link to={`${base + edit}/${param.hotel.id}`} target="_blank">
              {" "}
              {param.hotel?.name}{" "}
            </Link>
          ) : (
            "Unknown"
          );
        },
      },

      {
        header: "invoice",
        Component: ({ param }) => param.hotel?.invoice,
      },
      {
        header: "orderCount",
        Component: ({ param }) => numberWithCommas(param.orderCount),
      },
      {
        header: "totalAmount",
        Component: ({ param }) => "£ " + changePriceFormat(param.totalAmount, 2),
      },
      {
        header: "totalCommission",
        Component: ({ param }) => "£ " + changePriceFormat(param.totalCommission, 2),
      },
      {
        header: "iban",
        Component: ({ param }) => param.hotel.iban,
      },
      // {
      //   header: "createdAt",
      //   Component: ({ param }) => isoToDate(param.createdAt),
      // },
      {
        header: "Actions",
        Component: ({ param }) => <TableAction {...{ param, newRoute, onClick, ...props }} />,
      },
    ];
