import { ERRORS } from "enumerations";
import regex from "enumerations/regex";
import * as yup from "yup";

export const fieldNames = {
  "name": "name",
  "image": "image",
  "isNewCategory": "isNewCategory",
  "categoryType": "categoryType",
  "isActive": "isActive",
  "parent": "parent",
  translations: "translations",
  listOrderNumber: "listOrderNumber",
  translate_name: "translate_name",
  translate_language: "translate_language",
  language: "language",
  isMpuDisabled: "isMpuDisabled",
  isClickDisabled: "isClickDisabled",
  redirect: "redirect",
};

const translations = yup
  .object({
    [fieldNames.name]: yup.string().required(ERRORS.pleaseEnterYour("name")).label("name"),
    [fieldNames.language]: yup.object().required(ERRORS.pleaseEnterYour("language")).label("language"),
  })
  .defined();


export const validation = (URL_ID) =>
  yup.object().shape({
    [fieldNames.name]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.name)).label(fieldNames.name),
    [fieldNames.categoryType]: yup.object().required(ERRORS.pleaseEnterYour(fieldNames.categoryType)).label(fieldNames.categoryType),
    // [fieldNames.parent]: yup.object().required(ERRORS.pleaseEnterYour(fieldNames.parent)).label(fieldNames.parent),
    [fieldNames.isMpuDisabled]: yup.bool().default(false).label(fieldNames.isMpuDisabled),
    // [fieldNames.isNewCategory]: yup.bool().default(false).label("isNewCategory"),
    [fieldNames.isClickDisabled]: yup.bool().default(false).label("isClickDisabled"),
    [fieldNames.redirect]: yup.object().when(fieldNames.isClickDisabled, {
      is: false,
      then: yup.object().required(ERRORS.pleaseEnterYour(fieldNames.redirect)),
    }),
    [fieldNames.image]: yup.lazy(value => {
      switch (typeof value) {
        case 'object':
          return yup.object().required(ERRORS.pleaseEnterYour(fieldNames.image)).label(fieldNames.image) // schema for object
        case 'string':
          return yup.string().required(ERRORS.pleaseEnterYour(fieldNames.image)).label(fieldNames.image); // schema for string
        default:
          return yup.mixed(); // here you can decide what is the default
      }
    }),
    [fieldNames.translations]: yup.array().of(translations),
  })

