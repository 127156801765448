import React, { useEffect } from "react";
import "./App.scss";
import AppRoutes from "./AppRoutes";
// import actionRefreshToken from "redux/store/refreshToken/action";
import { useDispatch } from "react-redux";
import routes from "operations/routing/routes";
import { Navigate, Route, Routes } from "react-router-dom";
import Login from "containers/Login";
import Verify from "containers/Verify";
import { useSelector } from "react-redux";
import adminAction from "redux/store/admin/action";
import socketAction from "redux/store/socket/action";
import WithErrorHandler from "components/WithErrorHandler";
import Cookies from "universal-cookie";
import { activate } from "lib/NoSleep";
import StickyWhatsapp from "containers/StickyWhatsapp";

const cookies = new Cookies();

const App = () => {
	// const refreshTokenState = useSelector((state) => state.refreshToken);
	const token = useSelector((state) => state.token);
	const adminState = useSelector((state) => state.admin);
	const dispatch = useDispatch();
	const cookieToken = cookies.get("accessToken");
	// useEffect(() => {
	//   dispatch(actionRefreshToken.refreshTokenCheck(null));
	// }, [dispatch]);

	useEffect(() => {
		if (token.data) {
			dispatch(adminAction.request());
			localStorage.removeItem("state");
		}
	}, []);

	useEffect(() => {
		if (adminState?.data) dispatch(socketAction.request());
	}, [adminState]);

	console.log({ adminState, token, cookieToken }, "----> adminState");

	const canWakeLock = () => "wakeLock" in navigator;

	const handleWackeUp = async () => {
		let wakelock;
		async function lockWakeState() {
			if (!canWakeLock()) return;
			try {
				wakelock = await navigator.wakeLock.request();
				console.log({ wakelock });
				wakelock.addEventListener("release", () => {
					console.log("Screen Wake State Locked:", !wakelock.released);
				});
				console.log("Screen Wake State Locked:", !wakelock.released);
			} catch (e) {
				console.error("Failed to lock wake state with reason:", e.message);
			}
		}
		await lockWakeState();
		// function releaseWakeState() {
		//   console.log({ wakelock });
		//   if (wakelock) wakelock.release();
		//   wakelock = null;
		// }
		// setInterval(releaseWakeState, 1000);
	};

	useEffect(() => {
		// handleWackeUp()

		activate();
		// const interval = setInterval(() => console.log("weakup"), 5000);
		// return () => clearInterval(interval)
	}, []);
	return (
		<WithErrorHandler>
			{/* <StickyWhatsapp /> */}
			<Routes>
				<Route path={routes.login} element={<Login />} />
				<Route path={routes.verify} element={<Verify />} />
				<Route path={"*"} element={token?.data && cookieToken ? <AppRoutes /> : <Navigate to={"/login"} />} />
			</Routes>
		</WithErrorHandler>
	);
};

export default App;
