import { yupResolver } from "@hookform/resolvers/yup";
import PageTitle from "components/PageTitle";
import axiosClient from "lib/axiosConfig";
import toastify from "lib/toastify";
import api from "operations/network/api";
import routes from "operations/routing/routes";
import { useEffect, useMemo, useState } from "react";
import { Button, Col } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { fieldNames, validation } from "./validation";
import BranchSelector, { convertBranchData } from "containers/Branch/BranchSelector";

import FieldSelect from "components/FieldSelect";
import FieldText from "components/FieldText";
import { useDispatch } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import actionBreadcrumbs from "redux/store/breadcrumbs/action";
import cLog from "utils/cLog";
import getDataInObjectDepth from "utils/getDataInObject";
import { useMutationCustom } from "utils/useMutationCustom";
import { useQueryCustom } from "utils/useQueryCustom";
import { API_NAME, COMPONENT_NAMES, ROUTE_NAME, PARENT_ROUTE_NAME, PARENT_COMPONENT_NAMES } from "../enum";
import {
  CURRENCY_DATA,
  CURRENCY_OBJECT,
  DRINK_DATA,
  DRINK_DATA_OBJECT,
  ROLES,
  ROLES_OBJECT,
  STATUSES,
} from "enumerations";
import GalleryModal from "components/gallery/GalleryModal";
import CheckBox from "components/CheckBox";
import ProductSelector, { convertProductData } from "containers/Product/ProductSelector";
// import GalleryComponent from "components/gallery/GalleryComponent";
// import { convertToLabelValue } from "utils/convertToLabelValue";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useTranslation } from "react-i18next";

const Add = () => {
  const MySwal = withReactContent(Swal);
  const { t } = useTranslation();

  // ------------------------------------ route
  const newRoute = useMemo(() => getDataInObjectDepth(PARENT_ROUTE_NAME, routes), []);

  // ----------------------------------------------------------------------------- Fetching Functions

  const navigation = useNavigate();
  let { id, hotel } = useParams();
  // console.log({ id, hotel });
  const isAll = hotel === "add-item-all-hotels";
  const URL_ID = id ? id : "";
  // console.log({ isAll });

  const creating = async (params) => await axiosClient().post(api[API_NAME].base + "/" + hotel, params);
  const updating = async (params) => await axiosClient().patch(api[API_NAME].base + "/" + id, params);
  const getById = async () => await axiosClient().get(api[API_NAME].get + "/" + hotel + "/" + id);
  // const getRoles = async () => await axiosClient().get(api["role"].list);

  // ----------------------------------------------------------------------------- Form
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
    control,
    reset,
  } = useForm({
    resolver: yupResolver(validation(URL_ID)),
    mode: "onSubmit",
  });

  // ----------------------------------------------------------------------------- Mutation
  const onSuccessMutating = (response) => {
    console.log({ response });
    toastify.success({ title: "success" });
    if (response?.data?.length && isAll) {
      MySwal.fire({
        title: <strong>These hotels are duplicates and no product has been added to them!!</strong>,
        html: (
          <div className="card" style={{ maxHeight: "90vh", overflow: "auto" }}>
            <div className="card-body">
              <ul className="list-arrow">
                {response?.data?.map((x) => {
                  const { base, edit } = routes.hotel;

                  return (
                    <li key={x.id} className="d-flex text-nowrap justify-content-start align-items-center ">
                      <a href={`${base + edit}/${x.hotel.id}`} target="_blank">
                        {" "}
                        <strong style={{ minWidth: "100px" }} className="mr-2 text-start">
                          {x?.hotel?.name}
                        </strong>
                      </a>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        ),
        icon: "info",
        confirmButtonColor: "#3085d6",
        confirmButtonText: "ok!",
      }).then((resultX) => {
        if (resultX.isConfirmed) {
          navigation(
            isAll
              ? newRoute.base + newRoute[API_NAME].list
              : newRoute[API_NAME].base + newRoute[API_NAME].list + "/" + hotel
          );
        }
      });
    } else {
      navigation(
        isAll
          ? newRoute.base + newRoute[API_NAME].list
          : newRoute[API_NAME].base + newRoute[API_NAME].list + "/" + hotel
      );
    }
  };
  const { isLoading, data, mutate } = useMutationCustom({
    url: URL_ID ? updating : creating,
    invalidQuery: `create_${API_NAME}`,
    onSuccess: onSuccessMutating,
  });

  // ----------------------------------------------------------------------------- Query By ID
  const onSuccessDataById = ({ data }) => {
    let params = { ...data };

    reset({
      ...params,
      currency: CURRENCY_OBJECT[params.currency],
      delivery: DRINK_DATA_OBJECT[params.delivery],
      product: convertProductData(params?.product),
      // image: { _id: params?.image?._id, completedUrl: params?.image?.completedUrl, title: params?.image?.title },
    });
  };
  const dataById = useQueryCustom({
    name: `getById_${API_NAME}`,
    url: getById,
    onSuccess: onSuccessDataById,
    enabled: !!URL_ID,
  });

  //  ----------------------------------------- GETTING ROLES ----------------------------------------- //
  // const onSuccessRoles = ({ data }) => {
  //   setRolesOption(convertToLabelValue(data?.result));
  // };
  // const rolesData = useQueryCustom({
  //   name: "rolesData",
  //   url: getRoles,
  //   onSuccess: onSuccessRoles,
  //   params: { sort: "desc", page: 1, limit: 10, status: 1 },
  // });
  // --------------------------------------- Submit Form
  const onSubmit = () => {
    const values = getValues();
    const requestData = {
      currency: values.currency?.value,
      delivery: values.delivery?.value,
      product: values.product?.value,
      kdv: values.kdv,
      price: values.price,
      branch: values.branch?.value,
    };
    console.log({ requestData, values });
    mutate(requestData);
  };

  // ----------------------------------------------------------------------------- Constance
  const dispatch = useDispatch();
  const button = {
    title: `Back To ${COMPONENT_NAMES[0]} List`,
    link: isAll
      ? newRoute.base + newRoute[API_NAME].list
      : newRoute[API_NAME].base + newRoute[API_NAME].list + "/" + hotel,
  };

  const breadcrumbs = [
    { title: "Dashboard", link: routes.dashboard.base },
    { title: PARENT_COMPONENT_NAMES[0], link: newRoute.base },
    { title: COMPONENT_NAMES[0], link: newRoute[API_NAME].base + newRoute[API_NAME].list + "/" + hotel },
    {
      title: URL_ID ? `Edit` : "Add",
    },
  ];
  // ---------------------------------------------- EFFECTS
  useEffect(() => {
    dispatch(actionBreadcrumbs.set(breadcrumbs));
    if (hotel && !id) {
      setValue(fieldNames.role, ROLES_OBJECT["hotel"]);
    }
  }, [dataById]);
  // ----------------------------------------------------------------------------- Log
  const values = getValues();
  console.log({ dataById, values }, "Add");
  console.log({ URL_ID });
  console.log({ errors }, "errors");

  // ----------------------------------------------------------------------------- UseEffects

  //  ----------------------------------------- GALLERY ----------------------------------------- //
  const [showModal, setShowModal] = useState(false);
  const [thumbnail, setThumbnail] = useState(null);
  const handleGalleryModal = () => {
    setShowModal(!showModal);
  };
  const handleSelectThumbnail = (thumbnail) => {
    setValue(fieldNames.image, thumbnail, {
      shouldValidate: true,
    });
    setThumbnail(thumbnail);
  };

  return (
    <div>
      <GalleryModal
        {...{
          showModal,
          handleModal: handleGalleryModal,
          handleClickedImage: handleSelectThumbnail,
          selectedPhoto: thumbnail,
        }}
      />
      <PageTitle title={URL_ID ? `Edit` : `Add  ${COMPONENT_NAMES[0]}`} button={button} />
      <form onSubmit={handleSubmit(onSubmit)} id={"form-container "} noValidate>
        <div className="row mt-5">
          <Col md="8">
            <div className="col-12 border p-4">
              <div className="">
                <div className="b-1">
                  <h4 className="">{"Basic Information"}</h4>
                </div>
                <div className="mt-5">
                  <div className="col-12">
                    <div className="basic-form">
                      <FieldText
                        require
                        {...{
                          name: fieldNames.price,
                          register,
                          placeholder: "price",
                          label: "price ",
                          errors,
                          type: "number",
                        }}
                      />
                      <FieldText
                        require
                        {...{
                          name: fieldNames.kdv,
                          register,
                          placeholder: "kdv",
                          label: "kdv",
                          errors,
                          type: "number",
                        }}
                      />
                      <ProductSelector
                        {...{
                          Controller,
                          name: fieldNames.product,
                          register,
                          label: "product",
                          errors,
                          control,
                          setParams: {
                            ...(!isAll && { menu: hotel }),
                          },
                        }}
                      />
                      {isAll ? (
                        <BranchSelector
                          {...{
                            // Controller,
                            name: fieldNames.branch,
                            register,
                            label: "branch",
                            errors,
                            control,
                          }}
                        />
                      ) : (
                        ""
                      )}

                      <FieldSelect
                        require
                        {...{
                          Controller,
                          name: fieldNames.currency,
                          register,
                          label: "currency",
                          errors,
                          control,
                          options: CURRENCY_DATA,
                        }}
                      />

                      <FieldSelect
                        {...{
                          Controller,
                          name: fieldNames.delivery,
                          register,
                          label: "delivery",
                          errors,
                          control,
                          options: DRINK_DATA,
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col md="4">
            <div className="row">
              <Col md="12 border p-4">
                <div className="card">
                  <div className="">
                    <h4 className="card-title">{"Actions"}</h4>
                  </div>

                  <div className="card-body">
                    <div className="basic-form">
                      {/* <div className='publishing-actions'>
                        <div className='pub-section'>
                          <i className="fa glyphicon glyphicon-export fa-info-circle mr-2"></i>
                          <span>Status : </span>
                          <span className='text-success ml-2'> Active</span>
                        </div>
                      </div> */}

                      {/* <hr /> */}

                      <Button
                        block
                        type="success"
                        htmlType="submit"
                        className={`btn btn-success btn-sm`}
                        loading={isLoading}
                      >
                        {!isLoading ? t("publish") : "Loading..."}
                      </Button>
                    </div>
                  </div>
                </div>
              </Col>
            </div>
          </Col>
        </div>
      </form>
    </div>
  );
};

export default Add;

// <Controller control={control} name={fieldNames.status} render={
//     ({ field: { onChange, value } }) => {
//         let valid = value !== undefined
//         return <Dropdown>
//             <Dropdown.Toggle
//                 variant="outline-primary"
//                 size="sm"
//                 className="mt-1 mb-2"
//             >
//                 {valid ? value ? 'Active' : 'Disable' : 'Status'}
//             </Dropdown.Toggle>
//             <Dropdown.Menu>
//                 <Dropdown.Item onClick={() => onChange(true)} active={value}>Active</Dropdown.Item>
//                 <Dropdown.Item onClick={() => onChange(false)} active={!value}>Disable</Dropdown.Item>
//             </Dropdown.Menu>
//         </Dropdown>
//     }
// } >

// </Controller>
