import ModalLayout from "components/ModalLayout";
import { useState } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import DropZoonUpload from "./DropZoonUpload";
import DropDownBootstrap from "components/DropDownBootstrap";
import PrintPage from "components/PrintHotelLanding";
import PrintComponent from "utils/PrintComponent";
import styles from "./index.module.scss";
import bg1 from "assets/images/printQrCodeHotel/1.jpg";
import bg2 from "assets/images/printQrCodeHotel/2.jpg";
import bg3 from "assets/images/printQrCodeHotel/3.jpg";
import bg4 from "assets/images/printQrCodeHotel/4.jpg";
import bg5 from "assets/images/printQrCodeHotel/5.jpg";
import bg6 from "assets/images/printQrCodeHotel/6.jpg";
import bg7 from "assets/images/printQrCodeHotel/7.jpg";
import bg8 from "assets/images/printQrCodeHotel/8.jpg";
import bg9 from "assets/images/printQrCodeHotel/9.jpg";
import bg10 from "assets/images/printQrCodeHotel/10.jpg";

const optionsDesign = [
	{
		background: bg1,
		id: 1,
		dark: false,
	},
	{
		background: bg2,
		id: 2,
		dark: true,
	},
	{
		background: bg3,
		id: 3,
		dark: true,
	},
	{
		background: bg4,
		id: 4,
		dark: true,
	},
	{
		background: bg5,
		id: 5,
		dark: true,
	},
	{
		background: bg6,
		id: 6,
		dark: true,
	},
	{
		background: bg7,
		id: 7,
		dark: false,
	},
	{
		background: bg8,
		id: 8,
		dark: false,
	},
	{
		background: bg9,
		id: 9,
		dark: true,
	},
	{
		background: bg10,
		id: 10,
		dark: true,
	},
];

const options = [
	{
		label: "default",
		value: "",
	},
	{
		label: "catalog",
		value: "&isCatalog=true",
	},
	{
		label: "magnet",
		value: "&isMagnet=true",
	},
];

const PrintRoomNumbers = ({ data }) => {
	const [show, setShow] = useState();
	const [option, setOption] = useState(options[0]);
	const [image, setImage] = useState();
	const [design, setDesign] = useState();

	const { t } = useTranslation();

	const handleClickPrintRoomNumbers = () => {
		setShow(true);
	};
	const handleSetOption = (x) => {
		console.log({ x });
		setOption(x);
	};

	let printData = [
		{
			logo: image?.file,
			option: option?.value,
			uuid: data?.uuid,
		},
	];
	if (data?.roomNumbers?.length)
		printData.push(
			...data?.roomNumbers?.map((x) => ({
				logo: image?.file,
				roomNumber: x,
				option: option?.value,
				uuid: data?.uuid,
			}))
		);
	// else {
	//     // printData = new Array(data?.roomCount).fill()?.map((x, i) => {
	//     //     return (
	//     //         {
	//     //             logo: image?.file,
	//     //             roomNumber: i + 1,
	//     //             option: option?.value,
	//     //             uuid: data?.qrCode?.uuid
	//     //         }
	//     //     )
	//     // })
	//     printData = [{
	//         logo: image?.file,
	//         option: option?.value,
	//         uuid: data?.qrCode?.uuid
	//     }]
	// }

	const handleSetDesign = (x) => setDesign(x);

	function printContent() {
		PrintComponent(<PrintPage data={printData} background={design?.background} design={design} />, data?.name);
	}

	console.log({ data, printData, image });

	return (
		<div className="basic-form">
			<ModalLayout
				{...{
					setShow,
					show,
					title: "info",
					size: "lg",
				}}>
				<label>Logo</label>
				<DropZoonUpload {...{ image, setImage }} />

				<label className="mt-4">Select design</label>
				<div className={styles.designBox}>
					{optionsDesign.map((x) => {
						return (
							<div onClick={() => handleSetDesign(x)} key={x.id} className={[styles.designItem, x.id == design?.id ? styles.select : ""].join(" ")}>
								<img src={x.background} />
							</div>
						);
					})}
				</div>
				<div className="mt-3">
					<label>select (catalog or magnet)</label>
					<DropDownBootstrap
						{...{
							title: t("select"),
							data: options,
							click: handleSetOption,
							value: option,
						}}
					/>
				</div>
				<Button block type="button" htmlType="button" className={`btn btn-info btn-sm mt-5`} onClick={printContent}>
					{t("print")}
				</Button>
			</ModalLayout>
			<Button block type="button" htmlType="button" className={`btn btn-info btn-sm mt-5`} onClick={handleClickPrintRoomNumbers}>
				{t("print-hotel-room-numbers")}
			</Button>
		</div>
	);
};

export default PrintRoomNumbers;
