import { ERRORS } from "enumerations";
import regex from "enumerations/regex";
import * as yup from "yup";

export const fieldNames = {
  name: "name",
  shortDesc: "shortDesc",
  desc: "desc",
  stock: "stock",
  listPrice: "listPrice",
  image: "image",
  isNewProduct: "isNewProduct",
  isActive: "isActive",
  isFastDelivery: "isFastDelivery",
  category: "category",
  panelName: "panelName",
  listOrderNumber: "listOrderNumber",
  commercialTitle: "commercialTitle",
  name: "name",
  taxNo: "taxNo",
  taxAdministration: "taxAdministration",
  address: "address",
  defaultLanguage: "defaultLanguage",
  phoneNumber: "phoneNumber",
  roomCount: "roomCount",
  drink: "drink",
  isActive: "isActive",
  isOrderLimit: "isOrderLimit",
  commission: "commission",
  orderLimit: "orderLimit",
  serviceCharge: "serviceCharge",
  drinkInvoice: "drinkInvoice",
  foodInvoice: "foodInvoice",
  isOnlineActive: "isOnlineActive",
  isCardActive: "isCardActive",
  isCashActive: "isCashActive",
  branch: "branch",
  users: "users",
  user_email: "user_email",
  user_password: "user_password",
  user_confirm_password: "user_confirm_password",
  user_name: "user_name",
  user_lastName: "user_lastName",
  user_phoneNumber: "user_phoneNumber",
  user_role: "user_role",
  user_status: "user_status",
  user_image: "user_image",
  user_type: "user_type",
  user_isEmailNotificationActive: "user_isEmailNotificationActive",
  user_isPhoneNotificationActive: "user_isPhoneNotificationActive",
  user_isActive: "user_isActive",
  endTime: "endTime",
  startTime: "startTime",
  menus: "menus",
  menu_endTime: "menu_endTime",
  menu_startTime: "menu_startTime",
  menu_menu: "menu_menu",
  currentNotWorkingReason: "currentNotWorkingReason",
  paymentPeriod: "paymentPeriod",
  productsIntegrationCodes: "productsIntegrationCodes"
};

const users = yup.object({}).defined();

export const validation = (URL_ID) =>
  yup.object().shape({
    [fieldNames.name]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.name)).label(fieldNames.name),
    // [fieldNames.shortDesc]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.shortDesc)).label(shortDesc.name),
    // [fieldNames.desc]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.desc)).label(fieldNames.name),
    // [fieldNames.stock]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.stock)).label(fieldNames.name),
    [fieldNames.paymentPeriod]: yup.object().required(ERRORS.pleaseEnterYour(fieldNames.paymentPeriod)).label(fieldNames.paymentPeriod).label(fieldNames.paymentPeriod),
    [fieldNames.user_name]: yup
      .string()
      .required(ERRORS.pleaseEnterYour(fieldNames.user_name))
      .label(fieldNames.user_name),
    [fieldNames.user_lastName]: yup
      .string()
      .required(ERRORS.pleaseEnterYour(fieldNames.user_lastName))
      .label(fieldNames.user_lastName),
    [fieldNames.user_email]: yup
      .string()
      .required(ERRORS.pleaseEnterYour(fieldNames.user_email))
      .label(fieldNames.user_email),
    ...(URL_ID
      ? {
        [fieldNames.user_password]: yup
          .string()
          // .matches(regex.passwordRegExp, "Password must at least 8 characters, including uppercase,lowercase,number and symbol")
          .label("Password"),
      }
      : {
        [fieldNames.user_password]: yup
          .string()
          // .matches(regex.passwordRegExp, "Password must at least 8 characters, including uppercase,lowercase,number and symbol")
          .required(ERRORS.pleaseEnterYour("Password"))
          .label("Password"),
      }),
    [fieldNames.user_confirm_password]: yup
      .string()
      .oneOf([yup.ref(fieldNames.user_password), null], "Passwords must match"),
    [fieldNames.user_role]: yup.object().label(fieldNames.user_role),
    [fieldNames.user_phoneNumber]: yup.number().label(fieldNames.user_phoneNumber),
    [fieldNames.user_isActive]: yup.object().label(fieldNames.user_isActive),
    [fieldNames.user_isEmailNotificationActive]: yup.bool().default(false).label("user_isEmailNotificationActive"),
    [fieldNames.user_isPhoneNotificationActive]: yup.bool().default(false).label("user_isPhoneNotificationActive"),
    [fieldNames.isCardActive]: yup.bool().default(false).label("isCardActive"),
    [fieldNames.isCashActive]: yup.bool().default(false).label("isCashActive"),
    [fieldNames.isOnlineActive]: yup.bool().default(false).label("isOnlineActive"),
    [fieldNames.isOrderLimit]: yup.bool().default(false).label("isOrderLimit"),
    [fieldNames.isFastDelivery]: yup.bool().default(false).label("isFastDelivery"),
    [fieldNames.isNewProduct]: yup.bool().default(false).label("isNewProduct"),

    // [fieldNames.lastName]: yup.string().required(ERRORS.pleaseEnterYour("Last name")).label("LastName"),
    // [fieldNames.email]: yup.string().required(ERRORS.pleaseEnterYour("Email")).label("email"),
    // [fieldNames.password]: yup
    //   .string()
    //   .matches(regex.passwordRegExp, "Password must at least 8 characters, including uppercase,lowercase,number and symbol")
    //   .label("Password"),
    // [fieldNames.confirm_password]: yup.string().oneOf([yup.ref("password"), null], "Passwords must match"),
    // [fieldNames.role]: yup.object().label("role"),
    // [fieldNames.phoneNumber]: yup.string().label("mobile-number"),
    // [fieldNames.isActive]: yup.object().label("status"),
    // [fieldNames.type]: yup.object().required(ERRORS.pleaseSelectYour("Type")).label("Type"),
    // [fieldNames.image]: yup.object().nullable().required(ERRORS.pleaseSelectYour("image")).label("image"),
  });
