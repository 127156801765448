import React, { useState } from "react";
import { Badge, Form } from "react-bootstrap";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import compareTranslate from "utils/compareTranslate";
import ReactDatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import styles from "./DateInput.module.css";
import isoToDate, { convertToLondonTimeZone, setLocalZone, setUKZone } from "utils/isoToDate";
// import uk from 'date-fns/locale/uk';
// registerLocale('uk', uk)
// const moment = require('moment-timezone');

const FieldDate = ({
	control,
	name,
	label,
	valid,
	register,
	errors,
	require,
	type,
	error,
	placeholder,
	setState,
	onChange,
	disables,
	selected,
	...other
}) => {
	let newRegister = register ? register(name) : {};

	const [t] = useTranslation();
	const isWeekday = (date) => {
		const day = date.getDay();
		return day !== 0 && day !== 6;
	};
	return (
		<Form.Group className="form-group ">
			<div className={"d-flex justify-content-between"}>
				{label ? (
					<label htmlFor={name}>
						{!disables?.translate?.label ? compareTranslate(t, label) : label}
						{require ? " *" : ""}
					</label>
				) : (
					""
				)}
			</div>

			<div className="input-wrapper">
				{control ? (
					<Controller
						control={control}
						ref={newRegister}
						name={name}
						render={({ field: { onChange: change, value } }) => {
							function handleChange(params) {
								console.log("FieldDate", { params });
								onChange?.(params);
								change(params);
							}
							console.log("* * * FieldDate", { value }, value ? setLocalZone(value) : null);
							return (
								<div>
									<ReactDatePicker
										showTimeSelect
										timeFormat="p"
										timeIntervals={15}
										showTimeInput
										dateFormat="Pp"
										showYearDropdown
										className="form-control"
										id={name}
										name={name}
										placeholder={!disables?.translate?.placeholder ? compareTranslate(t, placeholder || label) : placeholder || label}
										onChange={(e) => handleChange(setUKZone(e))}
										selected={value ? setLocalZone(value) : null}
										{...other}
										// locale="uk"
									>
										<Badge className={styles.now} onClick={() => handleChange(setUKZone(new Date()))}>
											Now!
										</Badge>
									</ReactDatePicker>
								</div>
							);

							// console.log({ onChange, value, options },'');
							// return <Select className="custom-selector" placeholder={label} options={newOptions} value={value} onChange={handleChange} {...other} />;
						}}
					/>
				) : (
					<div>
						<ReactDatePicker
							// showTimeSelect
							timeFormat="p"
							timeIntervals={15}
							showTimeInput
							dateFormat="Pp"
							showYearDropdown
							className="form-control"
							id={name}
							name={name}
							placeholder={!disables?.translate?.placeholder ? compareTranslate(t, placeholder || label) : placeholder || label}
							onChange={(e) => {
								console.log("FieldDate", { e, uk: setUKZone(e) });
								onChange(setUKZone(e));
							}}
							selected={selected ? setLocalZone(selected) : null}
							{...other}
							{...newRegister}
							// locale="uk"
						>
							<Badge className={styles.now} onClick={() => onChange(setUKZone(new Date()))}>
								Now!
							</Badge>
						</ReactDatePicker>
					</div>
				)}
			</div>
			{errors?.[name] ? (
				<div className={`error-text mt-1  `}>{!disables?.translate?.error ? compareTranslate(t, errors?.[name]?.message) : errors?.[name]?.message}</div>
			) : (
				""
			)}
			{error ? <div className={`error-text mt-1  `}>{!disables?.translate?.error ? compareTranslate(t, error?.message) : error?.message}</div> : ""}
		</Form.Group>
	);
};
export default FieldDate;
