import toastify from "lib/toastify";
import api from "operations/network/api";
import { useEffect, useRef, useState } from "react";
import { convertBytes } from "utils/convertor";
import { useMutationCustom } from "utils/useMutationCustom";
import styles from "./UploadTab.module.scss";
import axiosClient from "lib/axiosConfig";
import { Form } from "react-bootstrap";
import Select from "react-select";
import { useQueryCustom } from "utils/useQueryCustom";
import Loading from "components/Loading";
import DragAndDrop from "./DragAndDrop";

const UploadTab = ({ handleClickedImage, selectedPhoto, handleModal, FOLDER_SLUG }) => {
  //  ----------------------------------------- STATES/REFS/CONST ----------------------------------------- //

  const [userUploadedFile, setUserUploadedFile] = useState(null);
  const [imageDimensions, setImageDimensions] = useState({});
  const imageRef = useRef(null);
  const [folderOptions, setFolderOptions] = useState([]);
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [folderError, setFolderError] = useState(true);
  const [previewImage, setPreviewImage] = useState(null);
  const [inputValue, setInputValue] = useState("");

  const sendingFile = async (params) => await axiosClient().post(api["invoice"].base, params);
  // const getFolders = async () => await axiosClient().get(api["folder"].list);

  //  ----------------------------------------- MUTATING ----------------------------------------- //
  const onSuccessMutating = () => {
    toastify.success({ title: "success" });
    resetFields();
  };
  const { isLoading, data, mutate } = useMutationCustom({
    url: sendingFile,
    invalidQuery: `galley_get`,
    onSuccess: onSuccessMutating,
  });
  //  ----------------------------------------- GETTING FOLDERS ----------------------------------------- //
  // const onSuccessGettingFolders = ({ data }) => {
  //   setFolderOptions(selectConvert(data?.result));
  // };
  // const foldersData = useQueryCustom({
  //   name: "foldersList",
  //   url: getFolders,
  //   onSuccess: onSuccessGettingFolders,
  //   params: { sort: "desc", page: 1, limit: 10, status: 1 },
  // });
  //  ----------------------------------------- HANDLERS ----------------------------------------- //
  const handleSelectFile = (e) => {
    console.log({ e }, "this is e");
    if (e.target) {
      // console.log({ file: e.target.files[0] }, "uploaded photo");
      setUserUploadedFile(e.target.files[0]);
    } else {
      setUserUploadedFile(e);
    }
  };

  const handleUploadButton = () => {
    const formData = new FormData();
    formData.append("file", userUploadedFile);
    // formData.append("folder", selectedFolder?.value);
    // inputValue && formData.append("title", inputValue);
    // console.log(formData, "form data");
    mutate(formData);
  };

  const handleCancelButton = () => {
    resetFields();
  };

  const handleImageLoad = (e) => {
    if (imageRef.current.naturalWidth) {
      setImageDimensions({
        width: imageRef.current.naturalWidth,
        height: imageRef.current.naturalHeight,
      });
    }
  };

  const handleSelectingFolder = (e) => {
    setSelectedFolder(e);
    setFolderError(false);
  };

  const handleInputValue = (e) => {
    setInputValue(e.target.value);
    // console.log({ value: e.target.value }, "input value");
  };
  //  ----------------------------------------- FUNCTIONS ----------------------------------------- //
  function selectConvert(data) {
    return data?.map((param) => ({ label: param?.name || param?.title, value: param._id, slug: param?.slug }));
  }

  function resetFields() {
    setUserUploadedFile(null);
    setSelectedFolder(null);
    setFolderError(true);
    setInputValue("");
  }

  //  ----------------------------------------- EFFECTS ----------------------------------------- //
  useEffect(() => {
    if (userUploadedFile) {
      const reader = new FileReader();
      reader.onload = (e) => {
        // handleClickedImage(e.target.result);
        console.log({ e }, "reader");
        setPreviewImage(e.target.result);
      };
      reader.readAsDataURL(userUploadedFile);
    }
  }, [userUploadedFile]);

  useEffect(() => {
    if (FOLDER_SLUG && folderOptions) {
      const foundFolder = folderOptions.find((item) => item.slug === FOLDER_SLUG);
      handleSelectingFolder(foundFolder);
    }
  }, [folderOptions, FOLDER_SLUG]);

  return (
    <div className={styles.uploadTab}>
      <div className={styles.titleWrapper}>
        <h3>Upload your File</h3>
      </div>
      {userUploadedFile ? (
        <div className={styles.flexWrapper}>
          <div className={styles.selectedPhotoWrapper}>{previewImage ? <img ref={imageRef} src={previewImage} alt="uploaded photo" onLoad={handleImageLoad} /> : ""}</div>
          <div className={styles.controlWrapper}>
            <div className={styles.photoDetails}>
              <p>Name: {userUploadedFile?.name}</p>
              <p>
                Size: {imageDimensions.width || imageDimensions.height ? imageDimensions?.width + " × " + imageDimensions?.height : "Calculating..."} - {convertBytes(userUploadedFile?.size)}
              </p>
              <p>Type: {userUploadedFile?.type}</p>
            </div>
            <div className={styles.extraInformationWrapper}>
              <h3>Upload :</h3>
              {/* <Form.Group className={styles.formGroup}>
                <div className={"d-flex justify-content-between"}>
                  <label htmlFor={"name"}>Title :</label>
                </div>
                <Form.Control id={"name"} name={"name"} type="text" placeholder="Choose a new title" onChange={handleInputValue} />
              </Form.Group>
              <Form.Group className={"form-group "}>
                <div className={"d-flex justify-content-between"}>
                  <label htmlFor={"folder"}>Folder * :</label>
                  {folderError && <div className={`error-text`}>Choose please !</div>}
                </div>
                <Select className="custom-selector" placeholder={"Choose a folder"} options={folderOptions} value={selectedFolder} onChange={handleSelectingFolder} />
              </Form.Group> */}

              <div className={styles.buttonsWrapper}>
                <button className="btn btn-success btn-sm mr-2 btn btn-primary" disabled={isLoading} onClick={handleUploadButton}>
                  {isLoading ? <Loading size="sm" /> : "Upload"}
                </button>
                <button className="btn btn-danger btn-sm btn btn-primary" disabled={isLoading} onClick={handleCancelButton}>
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        // <div className={styles.uploadHereWrapper}>
        <DragAndDrop {...{ handleSelectFile }}>
          <input type="file" name="file" id="file" accept=".jpg,.jpeg,.png" className={styles.fileInput} onChange={handleSelectFile} />
          <label htmlFor="file" className={styles.fileLabel}>
            Click to choose from your device
          </label>
          <p>or</p>
          <p className={styles.dragAndDrop}>Drag and drop your file !</p>
          <p className={styles.accepted}>accepted files: .jpg, .jpeg, .png , .pdf</p>
        </DragAndDrop>
        // </div>
      )}
    </div>
  );
};

export default UploadTab;
