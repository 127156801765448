// import { ADMIN_PERMISSIONS } from "enumerations/enums";
import initialState from "./initialState";

export const requestFunc = (state, { queryOptions }) => ({
  ...state,
  isFetching: true,
  queryOptions,
});

export const receiveFunc = (state, { data }) => {
  let permission = Object.keys(data?.role?.permissions || {})
  return {
    ...state,
    isFetching: false,
    errorMessage: null,
    data: {
      ...data,
      permissions: data?.role?.permissions,
      ...(data?.userType == "super_admin" ? { permissions: true } : ""

        // : ["branch", "hotel"].includes(data?.userType) ? { permissions: ADMIN_PERMISSIONS[data?.role]?.permissions } : {}
      )
      // ...(data?.role && { operation: ADMIN_PERMISSIONS(data?.permissions)[data?.role] })
    },

    // ...(state.data ? { data: { ...state.data, ...data } } : { data })
  };
};

export const changeInfoFunc = (state, { params }) => ({
  ...state,
  isFetching: false,
  ...params,
});

export const failureFunc = (state, { errorMessage }) => ({
  ...state,
  data: false,
  isFetching: false,
  errorMessage,
});

export const initialFunc = () => {
  return { ...initialState };
};
