export const API_NAME = "region";
export const ROUTE_NAME = "region";
export const COMPONENT_NAMES = ["region", "regions"];

export const DAYS_OBJECT = {
    monday: { label: 'monday', value: "monday" },
    tuesday: { label: 'tuesday', value: "tuesday" },
    wednesday: { label: 'wednesday', value: "wednesday" },
    thursday: { label: 'thursday', value: "thursday" },
    friday: { label: 'friday', value: "friday" },
    saturday: { label: 'saturday', value: "saturday" },
    sunday: { label: 'sunday', value: "sunday" },
}