export const API_NAME = "hourlyReport";
export const ROUTE_NAME = "hourlyReport";
export const COMPONENT_NAMES = ["hourlyReport", "hourlyReports"];
export const PERMISSION = "hourlyReports";


export const sortHourData = (data) => {
    const sortedHour = [8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 0, 1, 2, 3]

    let sorting = {}
    let sortingArray = []
    for (let i = 0; i < sortedHour.length; i++) {
        const curr = sortedHour[i];
        sorting[curr] = {
            hour: curr,
            id: i,
            index: i
        }
        sortingArray.push({
            hour: curr,
            index: i,
            id: i,

        })
    }
    for (let i = 0; i < data?.length; i++) {
        const curr = data[i];
        //   let   Object.values(sorting)
        if (sorting[curr.hour]) sortingArray[sorting[curr.hour].index] = curr

    }
    console.log("* * * sortHourData :", { data, sorting });

    return sortingArray //Object.values(sorting)
}