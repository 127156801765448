import TableAction from "components/TableAction";
import { numberWithCommas } from "utils/calculators";

import isoToDate from "utils/isoToDate";
import TableStatus from "components/TableStatus";
import { Link } from "react-router-dom";
import routes from "operations/routing/routes";
import OrderStatusButton from "./OrderStatusButton";
import { ORDER_STATUS_OBJECT, PAYMENT_DATA_OBJECT } from "enumerations";
import TranslateByObject from "../../../components/TranslateByObject";

export const tableData = ({ onClick, newRoute, ...props }) =>
  // columns: ["Name", "Network type", "Default", "Actions"],
  // body: data?.map((param) => {
  //     return [
  //         param?.name,
  //         param?.network_type,
  //         (param.is_default)?.toString(),
  //         () => {
  //             return (
  //                 <div className="d-flex">
  //                     <Link to={routes[ROUTE_NAME].edit + '/' + param?.id} className="btn btn-primary shadow btn-xs sharp mr-1"  >
  //                         <i className="fa fa-pencil"></i>
  //                     </Link>
  //                     <span className="btn btn-danger shadow btn-xs sharp pointer" onClick={() => onClick({ param, type: 'delete' })} >
  //                         <i className="fa fa-trash pointer"></i>
  //                     </span>
  //                 </div>
  //             )
  //         }
  //     ]
  // }),
  [
    //
    {
      header: "actions",
      Component: ({ param }) => (
        <TableAction {...{ param, newRoute, onClick, ...props }} />
      ),
    },
    {
      header: "status",
      Component: ({ param }) => {
        // console.log({ param, ORDER_STATUS_OBJECT });
        return param?.status ? (
          <OrderStatusButton
            {...{
              param,
              onClick,
              isBranch: props.isBranch,
              isHotel: props.isHotel,
              isAdmin: props.isAdmin,
              ...props
            }}
          />
        ) : (
          "-"
        );
      },
    },
    // {
    //   header: "scannedRoom",
    //   Component: ({ param }) => param?.scannedRoom || "-",
    // },
    ...(!props.isBranch ? [{
      header: "scannedRoom",
      Component: ({ param }) => param?.scannedRoom || "-",
    }] : []),
    ...(!props.isBranch ? [
      {
        header: "phoneNumber",
        Component: ({ param }) => param?.phoneNumber || "-",
      }
    ] : [])
    ,

    // {
    //   header: "delivery",
    //   Component: ({ param }) => param?.delivery?.telegramUsername || "-",
    // },
    ...(!props.isBranch ? [{
      header: "delivery",
      Component: ({ param }) => param?.delivery?.telegramUsername || "-",
    }] : []),
    ...(!props.isBranch ? [{
      header: "orderNumber",
      Component: ({ param }) => param?.orderNumber || "-",
    }] : []),
    ...(!props.isBranch ? [{
      header: "nameSurname",
      Component: ({ param }) => param?.name || "name",
    }] : []),

    ...(!props.isBranch ? [{
      header: "hotel",
      Component: ({ param }) => {
        const { base, edit } = routes.hotel;
        return param?.hotel ? (
          <Link to={`${base + edit}/${param.hotel.id}`} target="_blank">
            {" "}
            {param.hotel?.name}{" "}
          </Link>
        ) : (
          "-"
        );
      },
    }] : []),
    ...(!props.isBranch ? [{
      header: "roomNumber",
      Component: ({ param }) => param?.roomNumber || "-",
    }] : []),
    ...(!props.isBranch ? [{
      header: "totalPrice",
      Component: ({ param }) => "£ " + numberWithCommas(param.totalPrice),
    }] : []),

    // {
    //   header: "totalPriceTry",
    //   Component: ({ param }) =>
    //     numberWithCommas(param.totalPriceTry) || "-",
    // },

    // {
    //   header: "paymentCurrency",
    //   Component: ({ param }) =>
    //     param?.paymentCurrency === "try"
    //       ? "Türk Lirası"
    //       : param?.paymentCurrency === "usd"
    //       ? "Dolar"
    //       : param?.paymentCurrency === "eur"
    //       ? "Euro"
    //       : "-",
    // },

    // {
    //   header: "euro",
    //   Component: ({ param }) =>
    //     numberWithCommas((param.euro * 0.97).toFixed(2)) || "-",
    // },

    // {
    //   header: "dollar",
    //   Component: ({ param }) =>
    //     numberWithCommas((param.dollar * 0.97).toFixed(2)) || "-",
    // },

    // {
    //   header: "paymentType",
    //   Component: ({ param }) =>
    //     (
    //       <TranslateByObject
    //         {...(PAYMENT_DATA_OBJECT[param.paymentType] &&
    //           PAYMENT_DATA_OBJECT[param.paymentType])}
    //       />
    //     ) || "-",
    // },
    // {
    //   header: "region",
    //   Component: ({ param }) => {
    //     const { base, edit } = routes.region;
    //     // const currRegion = props?.isAdmin ? param?.region : param?.hotel?.region
    //     const currRegion = param?.hotel?.region;
    //     return currRegion ? (
    //       <Link to={`${base + edit}/${currRegion?.id}`} target="_blank">
    //         {" "}
    //         {currRegion?.name}{" "}
    //       </Link>
    //     ) : (
    //       "-"
    //     );
    //   },
    // },
    ...(!props.isBranch ? [{
      header: "region",
      Component: ({ param }) => {
        const { base, edit } = routes.region;
        // const currRegion = props?.isAdmin ? param?.region : param?.hotel?.region
        const currRegion = param?.hotel?.region;
        return currRegion ? (
          <Link to={`${base + edit}/${currRegion?.id}`} target="_blank">
            {" "}
            {currRegion?.name}{" "}
          </Link>
        ) : (
          "-"
        );
      },
    },] : []),
    {
      header: "note",
      Component: ({ param }) => param?.note || "-",
    },

    {
      header: "createdAt",
      Component: ({ param }) => isoToDate(param.createdAt),
    },
    // {
    //   header: "Status",
    //   Component: ({ param }) => <TableStatus {...{ param, onClick }} />,
    // },
  ];
