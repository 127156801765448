import { yupResolver } from "@hookform/resolvers/yup";
import PageTitle from "components/PageTitle";
import axiosClient from "lib/axiosConfig";
import toastify from "lib/toastify";
import api from "operations/network/api";
import routes from "operations/routing/routes";
import { useEffect, useMemo, useState } from "react";
import { Button, Col } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { fieldNames, validation } from "./validation";

import FieldSelect from "components/FieldSelect";
import FieldText from "components/FieldText";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import actionBreadcrumbs from "redux/store/breadcrumbs/action";
import cLog from "utils/cLog";
import getDataInObjectDepth from "utils/getDataInObject";
import { useMutationCustom } from "utils/useMutationCustom";
import { useQueryCustom } from "utils/useQueryCustom";
import { API_NAME, COMPONENT_NAMES, ROUTE_NAME } from "../enum";
import { DRINK_DATA, OPERATION, OPERATION_SAID, ROLES, STATUSES } from "enumerations";
import GalleryModal from "components/gallery/GalleryModal";
import CheckBox from "components/CheckBox";
import CategorySelector from "containers/Category/CategorySelector";
import ProductSelector from "containers/Product/ProductSelector";
import HotelSelector from "containers/Hotel/HotelSelector";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import moment from "moment";
import FieldDate from "components/FieldDate";
import { londonTimeZone, toUKTimezone } from "utils/isoToDate";
import { convertTimeTo24HourFormat } from "utils/convertor";
// import GalleryComponent from "components/gallery/GalleryComponent";
// import { convertToLabelValue } from "utils/convertToLabelValue";

const Add = () => {
	const MySwal = withReactContent(Swal);
	const [showPass, setShowPass] = useState(false);
	const [showConfirmPass, setShowConfirmPass] = useState(false);
	const [type, setType] = useState(null);
	// ------------------------------------ route
	const newRoute = useMemo(() => getDataInObjectDepth(ROUTE_NAME, routes), []);
	const typeOptions = [
		{ label: "User", value: "USER" },
		{ label: "Admin", value: "ADMIN" },
	];

	// ----------------------------------------------------------------------------- Fetching Functions
	const creating = async (params) => await axiosClient().patch(api[API_NAME].base, params);
	const updating = async (params) => await axiosClient().patch(api[API_NAME].base + "/" + URL_ID, params);
	const getById = async () => await axiosClient().get(api[API_NAME].get + "/" + URL_ID);
	// const getRoles = async () => await axiosClient().get(api["role"].list);

	const navigation = useNavigate();
	let { id, hotel } = useParams();
	const URL_ID = id ? id : "";
	// ----------------------------------------------------------------------------- Form
	const {
		register,
		handleSubmit,
		formState: { errors },
		getValues,
		setValue,
		control,
		reset,
		watch,
	} = useForm({
		resolver: yupResolver(validation(URL_ID)),
		mode: "onSubmit",
	});

	// ----------------------------------------------------------------------------- Mutation
	const onSuccessMutating = () => {
		toastify.success({ title: "success" });
		navigation(newRoute.base);
	};
	const { isLoading, data, mutate } = useMutationCustom({
		url: URL_ID ? updating : creating,
		invalidQuery: `getById_${API_NAME}`,
		onSuccess: onSuccessMutating,
	});

	// ----------------------------------------------------------------------------- Query By ID
	const onSuccessDataById = ({ data }) => {
		let params = { ...data };
		let selectedStatus = STATUSES.find((item) => item.value === params.isActive);

		handleSelectThumbnail({
			_id: params?.image?._id,
			completedUrl: params?.image?.completedUrl,
			title: params?.image?.title,
		});
		let date = "2017-03-13";

		reset({
			...params,
			role: { label: params?.role, value: params?.role },
			isActive: selectedStatus,
			startTime: params?.startTime && moment(date + " " + convertTimeTo24HourFormat(params?.startTime) + londonTimeZone).format(),
			endTime: params?.endTime && moment(date + " " + convertTimeTo24HourFormat(params?.endTime) + londonTimeZone).format(),
			// image: { _id: params?.image?._id, completedUrl: params?.image?.completedUrl, title: params?.image?.title },
		});
	};
	const dataById = useQueryCustom({
		name: `getById_${API_NAME}`,
		url: getById,
		onSuccess: onSuccessDataById,
		enabled: !!URL_ID,
	});

	//  ----------------------------------------- GETTING ROLES ----------------------------------------- //
	// const onSuccessRoles = ({ data }) => {
	//   setRolesOption(convertToLabelValue(data?.result));
	// };
	// const rolesData = useQueryCustom({
	//   name: "rolesData",
	//   url: getRoles,
	//   onSuccess: onSuccessRoles,
	//   params: { sort: "desc", page: 1, limit: 10, status: 1 },
	// });
	// --------------------------------------- Submit Form
	const onSubmit = () => {
		const values = getValues();

		const array = [];

		for (const label in values) {
			const value = values[label];
			array.push({ label, value });
		}
		console.log({ array });
		MySwal.fire({
			title: " Please check carefully!!",
			html: (
				<div className="card">
					<div className="card-body">
						<ul className="list-ticked">
							{array?.map(({ label, value }) => {
								console.log({ label, value });
								return (
									<li key={label} className="d-flex text-nowrap justify-content-start align-items-center ">
										<strong style={{ minWidth: "100px" }} className="mr-2 text-start">
											{label}
										</strong>{" "}
										<div className="mx-2">{":"}</div>
										<div className="text-start">{value?.value || (value?.[0]?.label && value?.map((x) => x.label)) || value}</div>{" "}
									</li>
								);
							})}
						</ul>
					</div>
					<h4 className="card-title">Are you sure?!</h4>
					<p className="card-description">If you confirm, the changes will be applied</p>
				</div>
			),
			icon: "info",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Yes, change it!",
		}).then((resultX) => {
			if (resultX.isConfirmed) {
				MySwal.fire({
					title: " Are you sure?! ",
					html: (
						<div className="card">
							<p className="card-description">If you confirm, the changes will be applied</p>
						</div>
					),
					icon: "warning",
					showCancelButton: true,
					confirmButtonColor: "#3085d6",
					cancelButtonColor: "#d33",
					confirmButtonText: "Yes, change it!",
				}).then((resultY) => {
					if (resultY.isConfirmed) {
						const requestData = {
							...values,
							hotels: !values.hotels?.find((x) => x.value === "all") ? values.hotels?.map((x) => x?.value) : undefined,
							category: !values.category?.find((x) => x.value === "all") ? values.category?.map((x) => x?.value) : undefined,
							product:
								//  !values.product?.find(x => x.value === "all")
								values.product?.value !== "all"
									? values.product?.value
									: //  ? values.product?.map((x) => x?.value)
									  undefined,
							delivery: values.delivery?.value !== "all" ? values.delivery?.value : undefined,
							operation: values.operation?.value !== "all" ? values.operation?.value : undefined,
							operationSide: values.operationSide?.value !== "all" ? values.operationSide?.value : undefined,
							startTime: (values?.startTime && toUKTimezone(values?.startTime)?.format("hh:mm A")) || "",
							endTime: (values?.endTime && toUKTimezone(values?.endTime)?.format("hh:mm A")) || "",
						};
						console.log("reqData", { requestData });
						mutate(requestData);
					}
				});
			}

			// console.log({ willX });
			// return MySwal.fire(<p>Shorthand works too</p>)
		});
		// swal({
		//   // title: "Are you sure?",
		//   html: <div className="card">
		//     <div className="card-body">
		//       <h4 className="card-title">List with icon</h4>
		//       <p className="card-description">Add class <code>.list-ticked</code> to <code>&lt;ul&gt;</code></p>
		//       <ul className="list-ticked">
		//         <li>Lorem ipsum dolor sit amet</li>
		//         <li>Consectetur adipiscing elit</li>
		//         <li>Integer molestie lorem at massa</li>
		//         <li>Facilisis in pretium nisl aliquet</li>
		//         <li>Nulla volutpat aliquam velit</li>
		//       </ul>
		//     </div>
		//   </div>,
		//   // icon: "warning",
		//   // buttons: true,
		//   // dangerMode: true,
		// }).then((willX) => {
		//   // if (willX) {
		//   //   swal({
		//   //     title: "Are you sure?",
		//   //     text: `You want to ${status?.label?.toLowerCase()} this ${COMPONENT_NAMES[0]}`,
		//   //     icon: "warning",
		//   //     buttons: true,
		//   //     dangerMode: true,
		//   //   }).then((willY) => {
		//   //     if (willY) {

		//   //     }
		//   //   });
		//   // }
		// });
		// const values = getValues();
		// const requestData = {
		//   ...values,
		//   hotel: values.hotel?.value !== "all" ? values.hotel?.value : undefined,
		//   category: values.category?.value !== "all" ? values.category?.value : undefined,
		//   product: values.product?.value !== "all" ? values.product?.value : undefined,
		//   delivery: values.delivery?.value !== "all" ? values.delivery?.value : undefined,
		//   operation: values.operation?.value !== "all" ? values.operation?.value : undefined,
		//   operationSide: values.operationSide?.value !== "all" ? values.operationSide?.value : undefined,
		// };
		// console.log({ requestData });
		// mutate(requestData);
	};

	// ----------------------------------------------------------------------------- Constance
	const dispatch = useDispatch();
	const button = {
		title: `Back To ${COMPONENT_NAMES[0]} List`,
		link: newRoute.base + newRoute.list,
	};

	const breadcrumbs = [
		{ title: "Dashboard", link: routes.dashboard.base },
		{ title: COMPONENT_NAMES[0], link: newRoute.base },
		{
			title: URL_ID ? `Edit / ${dataById?.data?.data?.result?.name} ${dataById?.data?.data?.result?.name}` : "Add",
		},
	];
	// ----------------------------------------------------------------------------- watcher hook

	const hotelState = watch(fieldNames.hotels);
	const categoryState = watch(fieldNames.category);
	// ----------------------------------------------------------------------------- Log
	const values = getValues();
	console.log({ dataById, values }, "Add");
	console.log({ URL_ID });
	console.log({ errors }, "errors");
	console.log({ hotelState, categoryState });

	// ----------------------------------------------------------------------------- UseEffects
	useEffect(() => {
		// setValue(fieldNames.category, false);
		// setValue(fieldNames.product, false);
	}, [hotelState]);
	// --------------------------------------
	useEffect(() => {
		setValue(fieldNames.product, false);
	}, [categoryState]);
	// --------------------------------------
	useEffect(() => {
		dispatch(actionBreadcrumbs.set(breadcrumbs));
	}, [dataById]);
	//  ----------------------------------------- GALLERY ----------------------------------------- //
	const [showModal, setShowModal] = useState(false);
	const [thumbnail, setThumbnail] = useState(null);
	const handleGalleryModal = () => {
		setShowModal(!showModal);
	};
	const handleSelectThumbnail = (thumbnail) => {
		setValue(fieldNames.image, thumbnail, {
			shouldValidate: true,
		});
		setThumbnail(thumbnail);
	};

	return (
		<div>
			<GalleryModal
				{...{
					showModal,
					handleModal: handleGalleryModal,
					handleClickedImage: handleSelectThumbnail,
					selectedPhoto: thumbnail,
				}}
			/>
			<PageTitle title={URL_ID ? `Edit  ${COMPONENT_NAMES[0]}` : `Add  ${COMPONENT_NAMES[0]}`} button={button} />
			<form onSubmit={handleSubmit(onSubmit)} id={"form-container "} noValidate>
				<div className="row mt-5">
					<Col md="8">
						<div className="col-12 border p-4">
							<div className="">
								<div className="b-1">
									<h4 className="">{"Basic Information"}</h4>
								</div>
								<div className="mt-5">
									<div className="col-12">
										<div className="basic-form">
											<HotelSelector
												require
												withAll
												isMulti
												{...{
													Controller,
													name: fieldNames.hotels,
													register,
													label: "hotels",
													errors,
													control,
												}}
											/>
											<CategorySelector
												require
												isMulti
												withAll
												// withParent
												{...{
													Controller,
													name: fieldNames.category,
													register,
													label: "category",
													errors,
													control,
													// setParams: {
													//   hotel: hotelState?.value !== "all" ? hotelState?.value : undefined,
													// },
												}}
											/>

											<ProductSelector
												require
												// isMulti

												withAll
												{...{
													Controller,
													name: fieldNames.product,
													register,
													label: "product",
													errors,
													control,
													// setParams: {
													//   hotel: hotelState?.value !== "all" ? hotelState?.value : undefined,
													//   category: categoryState?.value !== "all" ? categoryState?.value : undefined,
													// },
												}}
											/>

											<FieldSelect
												require
												withAll
												{...{
													Controller,
													name: fieldNames.delivery,
													register,
													label: "delivery",
													errors,
													control,
													options: DRINK_DATA,
												}}
											/>

											<FieldSelect
												require
												{...{
													Controller,
													name: fieldNames.operation,
													register,
													label: "operation",
													errors,
													control,
													options: OPERATION,
												}}
											/>

											<FieldSelect
												require
												{...{
													Controller,
													name: fieldNames.operationSide,
													register,
													label: "operationSide",
													errors,
													control,
													options: OPERATION_SAID,
												}}
											/>

											<FieldText
												require
												{...{
													name: fieldNames.operationUnit,
													register,
													placeholder: "operationUnit",
													label: "operationUnit ",
													errors,
												}}
											/>

											<FieldDate
												{...{
													control,
													name: fieldNames.startTime,
													register,
													placeholder: " 11:20 am",
													label: "startTime",
													errors,
												}}
												showTimeSelect
												showTimeSelectOnly
												timeIntervals={5}
												timeCaption="Time"
												dateFormat="h:mm aa"
												showTimeInput={false}
											/>

											<FieldDate
												{...{
													control,
													name: fieldNames.endTime,
													register,
													placeholder: " 11:20 am",
													label: "endTime",
													errors,
												}}
												showTimeSelect
												showTimeSelectOnly
												timeIntervals={5}
												timeCaption="Time"
												dateFormat="h:mm aa"
												showTimeInput={false}
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
					</Col>
					<Col md="4">
						<div className="row">
							<Col md="12 border p-4">
								<div className="card">
									<div className="">
										<h4 className="card-title">{"Actions"}</h4>
									</div>

									<div className="card-body">
										<div className="basic-form">
											{/* <div className='publishing-actions'>
                        <div className='pub-section'>
                          <i className="fa glyphicon glyphicon-export fa-info-circle mr-2"></i>
                          <span>Status : </span>
                          <span className='text-success ml-2'> Active</span>
                        </div>
                      </div> */}

											{/* <hr /> */}

											<Button block type="success" htmlType="submit" className={`btn btn-success btn-sm`} loading={isLoading}>
												{!isLoading ? "Publish" : "Loading..."}
											</Button>
										</div>
									</div>
								</div>
							</Col>
						</div>
					</Col>
				</div>
			</form>
		</div>
	);
};

export default Add;

// <Controller control={control} name={fieldNames.status} render={
//     ({ field: { onChange, value } }) => {
//         let valid = value !== undefined
//         return <Dropdown>
//             <Dropdown.Toggle
//                 variant="outline-primary"
//                 size="sm"
//                 className="mt-1 mb-2"
//             >
//                 {valid ? value ? 'Active' : 'Disable' : 'Status'}
//             </Dropdown.Toggle>
//             <Dropdown.Menu>
//                 <Dropdown.Item onClick={() => onChange(true)} active={value}>Active</Dropdown.Item>
//                 <Dropdown.Item onClick={() => onChange(false)} active={!value}>Disable</Dropdown.Item>
//             </Dropdown.Menu>
//         </Dropdown>
//     }
// } >

// </Controller>
