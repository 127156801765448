import CheckedComponent from "components/CheckedComponent";
import TableAction from "components/TableAction";
import TableStatus from "components/TableStatus";
import { INVOICE_DATA_OBJECT, PLACEHOLDER_IMAGE } from "enumerations";
import routes from "operations/routing/routes";
import { Badge, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

import isoToDate from "utils/isoToDate";
import ModalImage from "react-modal-image";
import TranslateByObject from "components/TranslateByObject";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);


export const tableData = ({ onClick, newRoute, ...props }) => [
  {
    header: "actions",
    Component: ({ param }) => (
      <TableAction {...{ param, newRoute, onClick, ...props }} />
    ),
  },
  {
    header: "File",
    Component: ({ param }) => {
      const isPdf = ["application/pdf"].includes(param?.file?.mimetype)

      return (
        <>
          <span className="d-block mb-1">
            {
              isPdf ? <a href={param?.file?.path} target="_blank">{

                // param?.file?.name
                param?.file?.filename

              }</a>
                :
                <img
                  src={param?.file?.path || PLACEHOLDER_IMAGE}
                  className="image-fluid"
                />
            }

          </span>
        </>
      );
    },
  },
  {
    header: "branch",
    Component: ({ param }) => {
      const { base, edit } = routes.branch;
      return param.branch ? (
        <Link to={`${base + edit}/${param.branch.id}`} target="_blank">
          {" "}
          {param.branch?.name}{" "}
        </Link>
      ) : (
        "-"
      );
    },
  },
  {
    header: "hotel",
    Component: ({ param }) => {
      const { base, edit } = routes.hotel;
      return param.hotel ? (
        <Link to={`${base + edit}/${param.hotel.id}`} target="_blank">
          {" "}
          {param.hotel?.name}{" "}
        </Link>
      ) : (
        "-"
      );
    },
  },
  {
    header: "createdAt",
    Component: ({ param }) => isoToDate(param.createdAt),
  },

  // {
  //   header: "Status",
  //   Component: ({ param }) => <TableStatus {...{ param, onClick }} />,
  // },
];
