import CheckedComponent from "components/CheckedComponent";
import TableAction from "components/TableAction";
import TableStatus from "components/TableStatus";
import { INVOICE_DATA_OBJECT, PLACEHOLDER_IMAGE } from "enumerations";
import routes from "operations/routing/routes";
import { Badge, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

import isoToDate from "utils/isoToDate";
import ModalImage from "react-modal-image";
import TranslateByObject from "components/TranslateByObject";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import ShowQrCode from "./ShowQrCode";

const MySwal = withReactContent(Swal);

const handleShow = (param) => {


  MySwal.fire({
    title: <strong>Scan QR Code?!</strong>,

    html: (
      <ShowQrCode param={param} />
      // <div className="card">
      //   <img src={param?.qrCode?.path || PLACEHOLDER_IMAGE} />

      //   <a
      //     target="_blank"
      //     href={`https://room-services.net/sayfa/urunler?ouid=${param?.qrCode?.uuid}`}
      //   >
      //     room-services.net/sayfa/urunler?ouid={param?.qrCode?.uuid}
      //   </a>
      // </div>
    ),


    icon: false,
    // showCancelButton: true,
    confirmButtonColor: "#3085d6",
    // cancelButtonColor: '#d33',
    confirmButtonText: "ok!",
  });
};

export const tableData = ({ onClick, newRoute, ...props }) => [
  {
    header: "Actions",
    Component: ({ param }) => <TableAction {...{ param, newRoute, onClick, ...props }} />,
  },
  {
    header: "qrCode",
    Component: ({ param }) => {
      return (
        <>
          <span
            className="d-block mb-1 cursor-pointer"
            onClick={() => handleShow(param)}
          >
            {/* <img src={param?.qrCode?.path || PLACEHOLDER_IMAGE} /> */}
            <Badge bg="info">
              <i className="mdi mdi-eye" />
            </Badge>
          </span>
        </>
      );
    },
  },

  // {
  //   header: "Magnet",
  //   Component: ({ param }) => {
  //     return (
  //       <>
  //         <span className="d-block mb-1 cursor-pointer" onClick={() => handleShow(param)}>
  //           <img src={param?.qrCode?.magnetPath || PLACEHOLDER_IMAGE} />
  //         </span>
  //       </>
  //     );
  //   },
  // },
  // {
  //   header: "change branch",
  //   Component: ({ param }) => {
  //     const { base, user } = routes.hotel;
  //     return (
  //       <Button

  //         className=" btn btn-primary shadow btn-xs sharp mr-1 action-btn rounded p-2 "
  //       >
  //         <i className="mdi mdi-pencil"></i>
  //       </Button>
  //     );
  //   },
  // },
  ...(props.validPermission ? [{
    header: "users",
    Component: ({ param }) => {
      const { base, user } = routes.hotel;
      return (
        <Link to={`${user.base + user.list}/${param.id}`} target="_blank">
          <Badge bg="info">
            <i className="mdi mdi-eye" />
          </Badge>
        </Link>
      );
    },
  }] : []),

  {
    header: "menus",
    Component: ({ param }) => {
      const { base, menu } = routes.hotel;
      return (
        <Link to={`${menu.base + menu.list}/${param.id}`} target="_blank">
          <Badge bg="info">
            <i className="mdi mdi-eye" />
          </Badge>
        </Link>
      );
    },
  },
  {
    header: "name",
    Component: ({ param }) => param.name || "-",
  },
  {
    header: "receptionStartTime",
    Component: ({ param }) => param.receptionStartTime || "-",
  },
  {
    header: "receptionEndTime",
    Component: ({ param }) => param.receptionEndTime || "-",
  },

  {
    header: "address",
    Component: ({ param }) => param.address || "-",
  },
  // {
  //   header: "isPhoneRequired",
  //   Component: ({ param }) => (
  //     <CheckedComponent checked={param.isPhoneRequired} />
  //   ),
  // },
  {
    header: "region",
    Component: ({ param }) => {
      const { base, edit } = routes.region;
      return param.region ? (
        <Link to={`${base + edit}/${param.region.id}`} target="_blank">
          {" "}
          {param.region?.name}{" "}
        </Link>
      ) : (
        "-"
      );
    },
  },
  // {
  //   header: "branch",
  //   Component: ({ param }) => {
  //     const { base, edit } = routes.branch;
  //     return param.branch ? (
  //       <Link to={`${base + edit}/${param.branch.id}`} target="_blank">
  //         {" "}
  //         {param.branch?.name}{" "}
  //       </Link>
  //     ) : (
  //       "-"
  //     );
  //   },
  // },
  {
    header: "commercialTitle",
    Component: ({ param }) => param.commercialTitle || "-",
  },
  {
    header: "commission",
    Component: ({ param }) => param.commission || "-",
  },
  {
    header: "defaultLanguage",
    Component: ({ param }) => param.defaultLanguage || "-",
  },

  // {
  //   header: "drink",
  //   Component: ({ param }) => <TranslateByObject {...(INVOICE_DATA_OBJECT[param.drink] && INVOICE_DATA_OBJECT[param.drink])} />
  // },
  {
    header: "invoice",
    Component: ({ param }) => (
      <TranslateByObject
        {...(INVOICE_DATA_OBJECT[param?.invoice] &&
          INVOICE_DATA_OBJECT[param?.invoice])}
      />
    ),
  },
  {
    header: "orderLimit",
    Component: ({ param }) => param.orderLimit,
  },
  {
    header: "orderTotalPrice",
    Component: ({ param }) => param.orderTotalPrice,
  },
  {
    header: "orderTotalPriceTry",
    Component: ({ param }) => param.orderTotalPriceTry,
  },
  {
    header: "isOrderLimit",
    Component: ({ param }) => {
      return (
        // <span className={`${param.type === "ADMIN" ? "text-primary" : param.type === "USER" ? "text-success" : "text-secondary"} text-capitalize`}>{param?.type?.toLowerCase() || "-"}</span>
        <CheckedComponent checked={param.isOrderLimit} />
      );
    },
  },
  {
    header: "phoneNumber",
    Component: ({ param }) => param.phoneNumber || "-",
  },
  {
    header: "roomCount",
    Component: ({ param }) => param.roomCount || "-",
  },
  {
    header: "serviceCharge",
    Component: ({ param }) => (
      <CheckedComponent checked={param.serviceCharge} />
    ),
  },
  {
    header: "taxAdministration",
    Component: ({ param }) => param.taxAdministration || "-",
  },
  {
    header: "taxNo",
    Component: ({ param }) => param.taxNo || "-",
  },
  {
    header: "createdAt",
    Component: ({ param }) => isoToDate(param.createdAt),
  },

  {
    header: "Status",
    Component: ({ param }) => <TableStatus {...{ param, onClick, disabled: props?.edit?.disabled }} />,
  },
];
