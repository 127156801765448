export const API_NAME = "discount";
export const ROUTE_NAME = "discount";
export const PERMISSION = "discounts";
export const COMPONENT_NAMES = ["discount", "discounts"];

export const discountTypes = {
	unit: {
		label: "unit",
		value: "unit",
	},
	percentage: {
		label: "percentage",
		value: "percentage",
	},
};
