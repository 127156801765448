import Spinner from "components/Spinner";
import routing from "operations/routing";
import React, { Suspense, useEffect } from "react";
import { Routes, Route, useNavigate, Navigate } from "react-router-dom";
import Navbar from "components/Navbar";

import Sidebar from "components/Sidebar";
import SettingsPanel from "components/SettingsPanel";
import Footer from "components/Footer";
import { useSelector } from "react-redux";
import routes from "operations/routing/routes";
import { useDispatch } from 'react-redux';
import SplashScreen from "components/SplashScreen";
import ProtectedRoute from "components/ProtectedRoute";
import { findElementsInTwoArrays } from "utils/findElementsInTwoArrays";
import actionUser from "redux/store/admin/action";
import { ADMIN_REDIRECT } from "enumerations/enums";


const AppRoutes = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch()
  const tokenState = useSelector((state) => state.token);
  const adminState = useSelector((state) => state.admin);

  let auth = tokenState?.data;
  // console.log({ auth, adminState });

  // && !adminState?.isFetching && !tokenState.isFetching
  useEffect(() => {
    if (!auth && !tokenState.isFetching) {
      navigate(routes.login);

      // dispatch(actionUser.logout());
    }

    // if (!adminState?.isFetching && !adminState?.data) {
    //   navigate(routes.login);
    //   // dispatch(actionUser.logout());
    // }
  }, [adminState, tokenState]);

  const redirect = ADMIN_REDIRECT(adminState?.data)


  return auth ? (
    <>
      <SplashScreen show={!adminState?.data} />
      {adminState?.data && tokenState?.data ? (
        <div className="container-scroller">
          <Navbar />

          <div className="container-fluid page-body-wrapper">
            <Sidebar />
            <div className="main-panel">
              <div className="content-wrapper">
                <Suspense fallback={<Spinner />}>
                  <Routes>
                    {routing.map((x) => {
                      // console.log(x?.name, { validROute: (adminState.data.operation || adminState.data).permissions[x?.name] })
                      return (
                        <Route
                          key={x.path}
                          path={x.path}
                          exact={x?.exact}
                          element={
                            (x?.permission && (adminState.data).permissions !== true)
                              ?
                              // const isValid = x?.supports?.find((x) => permission[x]) || permission?.[x?.name]
                              <ProtectedRoute isAllowed={(adminState.data)?.permissions?.[x?.name] || x?.supports?.find((x) => (adminState.data)?.permissions?.[x])} redirect={
                                // adminState?.data?.operation?.redirect
                                // "/"
                                // redirect[adminState?.data?.role]?.operation?.redirect ||
                                redirect
                              }>{x.element}</ProtectedRoute>
                              :
                              x.element
                            // <ProtectedRoute isAllowed={x?.permissions?.[0] === "ALL" ? true : findElementsInTwoArrays(adminState.data.userType.permissions, x.permissions ? x.permissions : [])}>
                            //   {x.element}
                            // </ProtectedRoute>
                          }
                        />
                      )
                    })}
                    <Route index element={<Navigate to={"/login"} />} />

                  </Routes>
                </Suspense>
                <SettingsPanel />
              </div>
              <Footer />
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  ) : (
    <></>
  );
};

export default AppRoutes;
