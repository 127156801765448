import React, { Fragment, useRef } from "react";
// import { InputError } from "components/forms/InputError";
import AsyncSelect from "react-select/async";
import { API_NAME } from "./enum";
import axiosClient from "lib/axiosConfig";
import { useQueryCustom } from "utils/useQueryCustom";
import api from "operations/network/api";
import { useMemo, useEffect } from "react";
import Spinner from "react-bootstrap/Spinner";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import compareTranslate from "utils/compareTranslate";
import { Form } from "react-bootstrap";

const LoadingIndicator = () => {
  return (
    <div className="mx-2">
      <Spinner animation="grow" size="sm" />
    </div>
  );
};

export const convertProductsData = (data, { withAll, t } = {}) => {
  const param = data?.map?.((x) => ({
    label: (
      <div className="d-flex align-items-center justify-items-center">
        <div className=" mt-1 d-flex align-items-center">
          <img width={30} height={30} src={x?.images} className={"rounded-circle"} />
        </div>
        <div className="ml-4 mt-1 d-flex align-items-center">{x?.name}</div>
        {x?.integrationCode ? <div className="ml-4 mt-1 d-flex align-items-center">({x?.integrationCode})</div> : ""}
      </div>
    ),
    value: x?.id,
  }));

  if (withAll)
    param?.unshift?.({
      label: (
        <div className="d-flex align-items-center justify-items-center">
          {/* <div style={{ width: "25px" }} className=' mt-1 d-flex align-items-center'>
            </div> */}
          <div className="ml-4 mt-1 d-flex align-items-center">{t("all")}</div>
        </div>
      ),
      value: "all",
    });

  return param;
};
export const convertProductData = (x) => ({
  label: (
    <div className="d-flex align-items-center justify-items-center">
      <div className=" mt-1 d-flex align-items-center">
        <img width={30} height={30} src={x?.images} className={"rounded-circle"} />
      </div>
      <div className="ml-4 mt-1 d-flex align-items-center">{x?.name}</div>
      {x?.integrationCode ? <div className="ml-1 mt-1 d-flex align-items-center">({x?.integrationCode})</div> : ""}
    </div>
  ),
  value: x?.id,
});

const ProductSelector = ({
  name,
  control,
  label,
  valid,
  register,
  errors,
  require,
  options,
  setState,
  withAll,
  setParams,
  isDisabled,
  ...other
}) => {
  // const [dataForSelectBox, setDataForSelectBox] = React.useState([])

  const [t] = useTranslation();

  const getting = async (params) => {
    // console.log({ params });
    const { search_text, limit, page } = params;
    return await axiosClient().get(api[API_NAME].list, {
      params: {
        limit: limit || 10,
        search_text,
        page,
        ...(setParams && setParams),
      },
    });
  };
  // ------------------------------------------------------- query Get
  const {
    data: { data = {} } = {},
    error,
    isError,
    isLoading,
    refetch,
  } = useQueryCustom({
    name: `${API_NAME}_get`,
    url: getting,
    // params: {}
    // params: { ...QueryString },
  });

  const searchRef = useRef();

  const loadOptions = async (inputValue, callback) =>
    new Promise((resolve, reject) => {
      if (searchRef.current) clearTimeout(searchRef.current);
      // if (!inputValue) return callback()
      const time = setTimeout(async () => {
        const params = {
          search_text: inputValue,
          limit: 50,
          page: 1,
        };
        await getting(params).then((x) => {
          resolve(callback(convertProductsData(x?.data?.result, { withAll, t })));
        });
      }, 1000);
      searchRef.current = time;
    });

  const dataForSelectBox = useMemo(() => {
    return convertProductsData(data?.result, { withAll, t });
  }, [data]);
  let newRegister = register ? register(name) : {};

  console.log({ data });

  useEffect(() => {
    refetch();
  }, [setParams]);
  console.log({ setParams });

  return (
    <>
      <Form.Group className="form-group ">
        {label ? (
          <div className={"d-flex justify-content-between"}>
            <label htmlFor={name}>
              {compareTranslate(t, label)}
              {require ? " *" : ""}
            </label>
            {errors ? <div className={`error-text `}>{compareTranslate(t, errors[name]?.message)}</div> : ""}
          </div>
        ) : (
          ""
        )}
        {/* {control ? */}
        <Controller
          control={control}
          ref={newRegister}
          name={name}
          render={({ field: { onChange, value } }) => {
            function handleChange(params) {
              onChange?.(params);
              setState?.(params);
            }
            // console.log({ onChange, value, options },'');
            return (
              <AsyncSelect
                className="custom-selector"
                cacheOptions
                loadOptions={loadOptions}
                defaultOptions={dataForSelectBox}
                onChange={handleChange}
                components={{ LoadingIndicator }}
                classNamePrefix="select"
                placeholder={t("selectSearch")}
                styles={{
                  input: (base) => ({
                    ...base,
                    "input:focus": {
                      boxShadow: "none",
                    },
                  }),
                  control: (styles) => ({
                    ...styles,
                    borderColor: error ? "red" : styles.borderColor,
                  }),
                }}
                // validationState={error ? "error" : "default"}
                value={value}
                isDisabled={isDisabled || isLoading}
                {...other}
              />
            );
            //   return <Select className="custom-selector" placeholder={label} options={newOptions}
            //    value={value} onChange={handleChange} {...other} />
          }}
        />
        {/* :
        <Select className="custom-selector" options={options} {...other} />
      } */}
      </Form.Group>

      {/* <InputError error={error} /> */}
    </>
  );
};
export default ProductSelector;
