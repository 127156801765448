import CheckedComponent from "components/CheckedComponent";
import TableAction from "components/TableAction";
import IsWorking from "components/IsWorkingStatus";
import { PLACEHOLDER_IMAGE } from "enumerations";
import routes from "operations/routing/routes";
import { Link } from "react-router-dom";

import isoToDate from "utils/isoToDate";
export const tableData = ({ onClick, newRoute }) => [
  {
    header: "Status",
    Component: ({ param }) => <IsWorking {...{ param, onClick }} />,
  },
  {
    header: "Actions",
    Component: ({ param }) => <TableAction {...{ param, newRoute, onClick }} />,
  },
  {
    header: "integrationCode",
    Component: ({ param }) => param.integrationCode,
  },
  {
    header: "startTime",
    Component: ({ param }) => param.startTime,
  },
  {
    header: "endTime",
    Component: ({ param }) => param.endTime,
  },
  {
    header: "createdAt",
    Component: ({ param }) => isoToDate(param.createdAt),
  },
  {
    header: "updatedAt",
    Component: ({ param }) => isoToDate(param.updatedAt),
  },
];
