import { put, call, takeEvery, all, delay, take } from "redux-saga/effects";
import actionTypes from "../actionTypes";
import { eventChannel } from "redux-saga";
// import cLog from "utils/cLog";


// function* runEvent({ params }) {

//     try {
//         const res = yield call(network.patch, { url: api.user.changePassword, params });
//         yield put(action.receive(res.data));
//         yield delay(2000);
//         yield put(action.initial());
//         yield put(globalSendCodeAction.initial());
//     } catch (error) {
//         // console.log({ error });
//         yield put(action.failure(changeError(error)));
//         yield delay(2000);
//         yield put(action.failure(null));
//     }
// }

function subscribe(socket) {
    return eventChannel((emit) => {
        // console.log({ emit });

        const handler = (data) => {
            // console.log("Data in emitter ->>>>>>>", data);
            emit(data);
        };

        // socket.on("/orders", handler);
        socket.on("new_order ", handler);
        console.log("socket listening on order");


        return () => {
            // socket.off("welcome", handler);
            socket.off("new_order", handler);
        };
    });
}

function* runChanel({ socket }) {

    try {
        const channel = yield call(subscribe, socket);

        try {
            while (true) {
                let event = yield take(channel);

                console.log({ event });

                if (event === "Connect") {
                }
                if (event.data.payload && event.data.messageType && event.data.messageType == "serverStatus") {
                }
            }
        } catch (error) {
            console.log("Error in saga chanel ->>>>>>>", error);
        }

        // yield put(action.receive(res.data));
        // yield delay(2000);
        // yield put(action.initial());
        // yield put(globalSendCodeAction.initial());
    } catch (error) {
        console.log({ error });
        // yield put(action.failure(changeError(error)));
        // yield delay(2000);
        // yield put(action.failure(null));
    }
}




export default function* rootSaga() {
    yield all([
        takeEvery(actionTypes.RUN_EVENTS, runChanel),
    ]);
}