import PageTitle from "components/PageTitle";
import TableComponent from "components/TableComponent/index";
import { STATUSES } from "enumerations";
import axiosClient from "lib/axiosConfig";
import toastify from "lib/toastify";
import api from "operations/network/api";
import routes from "operations/routing/routes";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import actionBreadcrumbs from "redux/store/breadcrumbs/action";
import swal from "sweetalert";
import getDataInObjectDepth from "utils/getDataInObject";
import { useMutationCustom } from "utils/useMutationCustom";
import { useQueryCustom } from "utils/useQueryCustom";
import useQueryString from "utils/useQueryString";
import { API_NAME, COMPONENT_NAMES, ROUTE_NAME, PERMISSION } from "../enum";
import { tableData } from "./tableInfo";
import DatePicker from "react-datepicker";
import Factor from "containers/Factor";
// import { useReactToPrint } from "react-to-print";
import exportFromJSON from "export-from-json";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { useTranslation } from "react-i18next";
import isoToDate from "utils/isoToDate";
import DeliveriesSelector from "containers/Deliveries/DeliveriesSelector";
import cLog from "utils/cLog";
import { Button, Modal } from "react-bootstrap";
import { fieldNames, validation } from "./validation";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import BranchModal from "./../../../components/BranchModal/index";
import ShowModal from "./component/ShowModal";
import secondsToHms from "utils/secondsToHms";
import NoteForStatusCancel from "./component/NoteForStatusCancel";

const List = () => {
  const [checkList, setCheckList] = useState();
  const [showModal, setShowModal] = useState(false);
  const [modalStatusCancel, setModalStatusCancel] = useState({
    show: false,
  });
  const [show, setShow] = useState(false);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [currentEditingOrder, setCurrentEditingOrder] = useState("");
  const adminState = useSelector((state) => state.admin);
  const validPermission = adminState?.data?.permissions === true;
  const permissions =
    validPermission || adminState?.data?.permissions?.[PERMISSION];
  const statisticsPermissions =
    validPermission || adminState?.data?.permissions?.["statistics"];
  const isBranch = adminState.data.userType === "branch";
  const isHotel = adminState.data.userType === "hotel";
  const isAdmin = adminState.data.userType?.includes("admin");
  // ---------------------------------------------- new route
  const newRoute = useMemo(() => getDataInObjectDepth(ROUTE_NAME, routes), []);
  // ---------------------------------------------- Constance
  // const button = { title: `Add ${COMPONENT_NAMES[0]}`, link: newRoute.base + newRoute.add };
  const QueryString = useQueryString({ sort: "desc" });
  const { page, limit } = QueryString;
  const dispatch = useDispatch();

  // ---------------------------------------------- fetching functions
  const getting = async () =>
    await axiosClient().get(api[API_NAME].list, { params: { ...QueryString } });
  const gettingStatistics = async () => {
    const { from, to } = QueryString;
    return await axiosClient().get(api[API_NAME].statistics.v1, {
      params: { from, to },
    });
  };
  const gettingStatisticsV2 = async () => {
    const { from, to } = QueryString;
    return await axiosClient().get(api[API_NAME].statistics.v2, {
      params: { from, to },
    });
  };

  const gettingStatisticHotels = async () => {
    const { from, to } = QueryString;
    return await axiosClient().get(api[API_NAME].statistics.hotel, {
      params: { from, to },
    });
  };


  const gettingStatisticRooms = async () => {
    const { from, to } = QueryString;
    return await axiosClient().get(api[API_NAME].statistics.room, {
      params: { from, to },
    });
  };


  const gettingOrders = async () => {
    const { from, to } = QueryString;
    return await axiosClient().get(api[API_NAME].products, {
      params: { from, to },
    });
  };

  const DeliveryReport = async () => {
    const { from, to } = QueryString;
    return await axiosClient().get(api[API_NAME].deliveryReport, {
      params: { from, to },
    });
  };

  const changeStatus = async (params) => {
    console.log(params, "changeStatus");
    const data = {
      paymentType: params?.data?.paymentType,
      delivery: params?.delivery,
      status: params.status,
      ...(params.note && { cancelNote: params.note }),
    };
    return await axiosClient().patch(
      api[API_NAME].base + "/" + params?.data?.id,
      data
    );
    // await axiosClient().patch(api[API_NAME].changeStatus, params);
  };

  // ------------------------------------------------------- query Get
  const {
    data: { data = {} } = {},
    error,
    isError,
    isLoading,
    refetch,
  } = useQueryCustom({
    name: `${API_NAME}_get`,
    url: getting,
    params: { ...QueryString },
    enabled: !!(validPermission || permissions?.list),
  });
  // ---------------------------------------------- mutation Change Status
  const onSuccessChangeStatusMutation = () => {
    toastify.success({ title: "success" });
  };

  const changeStatusMutation = useMutationCustom({
    name: `${API_NAME}_changeStatus`,
    url: changeStatus,
    invalidQuery: `${API_NAME}_get`,
    onSuccess: onSuccessChangeStatusMutation,
  });

  // ---------------------------------------------- PRINT

  // const handlePrint = ({ params }) => useReactToPrint({
  //   content: <Factor {...{ params }} />,
  //   // documentTitle: "AwesomeFileName",
  //   // onBeforeGetContent: handleOnBeforeGetContent,
  //   // onBeforePrint: handleBeforePrint,
  //   // onAfterPrint: handleAfterPrint,
  //   removeAfterPrint: true
  // });
  // ---------------------------------------------- logs
  console.log({ data, error, isError, isLoading });
  // ---------------------------------------------- action click table

  const modalStyles = {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: `translate(-50%, -50%)`,
    backgroundColor: "white",
    width: "300px",
    height: "300px",
    borderRadius: "5px",
    padding: "20px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  };

  const actionsOperation = ({ data, status, note }) => {
    console.log("action statu ===>", status);

    console.log("action isOpen ===>", showModal);
    {
      if (status.value === "on_way") {
        setShowModal(true);
        setCurrentEditingOrder(data.id);
      } else {
        setShowModal(false);
        setCurrentEditingOrder("");
        swal({
          title: "Are you sure?",
          text: `You want to ${status?.label?.toLowerCase()} this ${COMPONENT_NAMES[0]
            }`,
          icon: "warning",
          buttons: true,
          dangerMode: true,
        }).then((willBe) => {
          if (willBe) {
            changeStatusMutation.mutate({ data, status: status.value, note });
            if (status.value === "payment_success") {
              console.log("triggred");
            }
          }
        });
      }
    }
  };

  const onWayStatusAction = () => {
    const { delivery } = getValues();
    const requestData = {
      status: "on_way",
      delivery: delivery.value,
      data: {
        id: currentEditingOrder,
      },
    };
    console.log("requestData", { requestData });
    changeStatusMutation.mutate(requestData);
    setShowModal(false);
  };

  const clickAction = ({ data, status }) => {
    // if (status.label === "print") return handlePrint(data)
    // const newData = data.map((x) => x.id);
    if (status?.value === "canceled") {
      setModalStatusCancel({ data, status: status, show: true });
    } else actionsOperation({ data: data, status });
  };

  const handleAction = (status) => {
    const data = Object.keys(checkList);
    // console.log({ data, status });
    actionsOperation({ data, status: status });
  };

  const exportToCSV = ({ apiData, fileName, head, cols }) => {
    const workbook = XLSX.utils.book_new();
    // const myHeader = ["id","name","location"];
    const worksheet = XLSX.utils.json_to_sheet(apiData, { header: head });

    const range = XLSX.utils.decode_range(worksheet["!ref"]);
    range.e["c"] = head.length - 1;
    worksheet["!ref"] = XLSX.utils.encode_range(range);
    worksheet["!autofilter"] = { ref: `A1:${String.fromCharCode(65 + head.length - 1)}1` };
    worksheet["!cols"] = cols;

    XLSX.utils.book_append_sheet(workbook, worksheet, "tab1");
    // XLSX.utils.book_append_sheet(workbook, worksheet, "tab2");
    // XLSX.writeFile(workbook, (fileName || "excel_export") + ".xlsx");
    XLSX.writeFile(workbook, ((fileName || "excel_export") + "_" + adminState?.data?.name + "_" + adminState?.data?.lastName) + ".xlsx");

    // const ws = XLSX.utils.json_to_sheet(apiData);
    // /* custom headers */
    // XLSX.utils.sheet_add_aoa(ws, [head], { origin: "A1", });
    // const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    // const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    // const data = new Blob([excelBuffer], { type: fileType });
    // FileSaver.saveAs(data, fileName + fileExtension);
  };

  const handleGetFullDataWithDate = () => {
    let cols = [
      { wch: 30 },
      { wch: 15 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
    ];

    axiosClient()
      .get(api[API_NAME].fullData, {
        params: { ...QueryString, limit: 0, page: 1 },
      })
      .then((x) => {
        console.log({ x });
        const head = [
          t("name"),
          t("orderNumber"),
          t("roomNumber"),
          t("paymentType"),
          t("status"),
          t("totalPrice"),
          t("hotelName"),
          // t("bayi"),
          t("createdAt"),
        ];
        const data = x?.data?.map((y) => ({
          [t("name")]: y?.name,
          [t("orderNumber")]: y?.orderNumber,
          [t("roomNumber")]: y?.roomNumber,
          [t("paymentType")]: y?.paymentType,
          [t("status")]: y?.status,
          [t("totalPrice")]: y?.totalPrice,
          [t("hotelName")]: y?.hotelName,
          // [t("bayi")]: y?.branch.panelName,
          [t("createdAt")]: isoToDate(y.createdAt),
        }));
        console.log("data =====>", data);

        exportToCSV({ apiData: data, fileName: "allOrders", head, cols });
      })
      .catch((err) => {
        console.log({ err });
      });
  };

  const handleGetStatistics = () => {
    let cols = [
      { wch: 30 },
      { wch: 15 },
      { wch: 20 },
      // { wch: 20 },
      // { wch: 20 },
      // { wch: 20 },
      // { wch: 20 },
    ];
    gettingStatistics()
      .then((x) => {
        console.log({ x });

        const head = [
          // "otel_uid",
          "hotel",
          "pleksi_scan",
          "pleksi_order",
          // "bayi",
          // "magnet_scan",
          // "magnet_siparis",
          // "katalog_ziyaret",
          // "katalog_siparis",
          // "toplam_siparis_adedi",
          // "toplam_ziyaret_adedi",
        ];
        const data = x?.data?.map((y) => ({
          // otel_uid: y?.uuid,
          hotel: y?.name,
          pleksi_scan: y?.totalView,
          pleksi_order: y?.totalOrder,
          // bayi: y?.branchName,
          // magnet_siparis: y?.totalMagnetOrder,
          // magnet_scan: y?.totalMagnetView,
          // katalog_ziyaret: y?.totalCatalogView,
          // katalog_siparis: y?.totalCatalogOrder,
          // toplam_siparis_adedi:
          //   y?.totalCatalogOrder + y?.totalMagnetOrder + y?.totalOrder,
          // toplam_ziyaret_adedi:
          //   y?.totalCatalogView + y?.totalMagnetView + y?.totalView,
        }));
        exportToCSV({
          apiData: data,
          fileName: `statistic-from-${QueryString.from || "now"}-to-${QueryString.to || "now"
            }`,
          head,
          cols,
        });
      })
      .catch((err) => {
        console.log({ err });
      });
  };

  const handleGetStatisticsV2 = () => {
    let cols = [
      { wch: 30 },
      { wch: 15 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
    ];
    gettingStatisticsV2()
      .then((x) => {
        console.log({ x });

        const head = [
          // "otel_uid",
          "otel",
          "region",
          "roomCount",
          "type",
          "successful",
          "date",
        ];
        const data = x?.data?.map((y) => ({
          otel: y?.hotelName,
          region: y?.regionName,
          roomCount: y?.roomNumber,
          type: y?.source,
          successful: String(y?.isOrderSubmitted),
          date: isoToDate(y.createdAt),
        }));
        exportToCSV({
          apiData: data,
          fileName: `statistic-details-from-${QueryString.from || "now"}-to-${QueryString.to || "now"
            }`,
          head,
          cols,
        });
      })
      .catch((err) => {
        console.log({ err });
      });
  };



  const handleGetStatisticRooms = () => {
    let cols = [{ wch: 30 }, { wch: 15 }, { wch: 15 }];
    gettingStatisticRooms()
      .then((x) => {
        console.log({ x });

        const workbook = XLSX.utils.book_new();

        const head = [t("rooms-scans-orders"), t("scan-count"), t("orderCount")];
        x?.data?.map((y) => {

          let apiData = y?.rooms?.map((z) => ({
            [t("rooms-scans-orders")]: z?.roomNumber,
            [t("scan-count")]: z?.quantity,
            [t("orderCount")]: z?.orderCount,
          }))

          const worksheet = XLSX.utils.json_to_sheet(apiData, { header: head });
          const range = XLSX.utils.decode_range(worksheet["!ref"]);
          range.e["c"] = head.length - 1;
          worksheet["!ref"] = XLSX.utils.encode_range(range);
          worksheet["!autofilter"] = { ref: `A1:${String.fromCharCode(65 + head.length - 1)}1` };
          worksheet["!cols"] = cols;

          let limitedString = y?.hotel?.length > 30 ? y?.hotel?.substring?.(0, 30) : y?.hotel;
          XLSX.utils.book_append_sheet(workbook, worksheet, limitedString);

        });

        XLSX.writeFile(workbook, (`statistic-rooms-from-${QueryString.from || "now"}-to-${QueryString.to || "now"}` + "_" + adminState?.data?.name + "_" + adminState?.data?.lastName) + ".xlsx");
      })
      .catch((err) => {
        console.log({ err });
      });
  };

  const handleGetStatisticHotels = () => {
    let cols = [{ wch: 30 }, { wch: 15 }, { wch: 15 },
    { wch: 15 },

    ];
    gettingStatisticHotels()
      .then((x) => {
        console.log({ x });

        const head = [t("hotelName"), t("orderCount"), t("scanCount"),
        t("regionName"),

        ];
        const data = x?.data?.map((y) => ({
          [t("hotelName")]: y?.hotelName,
          [t("orderCount")]: y?.orderCount,
          [t("scanCount")]: y?.scanCount,
          [t("regionName")]: y?.regionName,
        }));
        exportToCSV({
          apiData: data,
          fileName: `statistic-from-${QueryString.from || "now"}-to-${QueryString.to || "now"
            }`,
          head,
          cols,
        });
      })
      .catch((err) => {
        console.log({ err });
      });
  };
  //handleGetDetailedOrdersWithDate

  const handleGetDetailedOrdersWithDate = () => {
    let cols = [
      { wch: 30 },
      { wch: 15 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
    ];
    gettingOrders()
      .then((x) => {
        console.log({ x });

        const head = [
          t("id"),
          t("branch"),
          t("hotel"),
          t("orderNumber"),
          t("status"),
          t("paymentType"),
          t("quantity"),
          t("tax"),
          t("buyPrice"),
          // t("totalBuyPrice"),
          t("totalPrice"),
          t("productName"),
          t("integrationCode"),
          t("date"),
        ];
        const data = x?.data?.map((y) => ({
          [t("id")]: y?.id,
          [t("branch")]: y?.branch.name,
          [t("hotel")]: y?.hotelName,
          [t("orderNumber")]: y?.orderNumber,
          [t("status")]: y?.status,
          [t("paymentType")]: y?.paymentType,
          [t("quantity")]: y?.quantity,
          [t("tax")]: y?.kdv,
          [t("totalPrice")]: y?.price,
          [t("buyPrice")]: y?.buyPrice,
          // totalBuyPrice: y?.buyPrice * y?.quantity,
          [t("productName")]: y?.productName,
          [t("integrationCode")]: y?.productIntegrationCode,
          [t("date")]: isoToDate(y.createdAt),
        }));
        exportToCSV({
          apiData: data,
          fileName: `detailed-order-from-${QueryString.from || "now"}-to-${QueryString.to || "now"}`,
          head,
          cols
        });
      })
      .catch((err) => {
        console.log({ err });
      });
  };

  const handleGetDeliveryReportWithDate = () => {
    DeliveryReport()
      .then((x) => {
        console.log({ x });

        const head = [
          "deliveryName",
          "orderNumber",
          "regionName",
          "guestName",
          "hotelName",
          "roomNumber",
          "totalPrice",
          "totalPriceTry",
          "paymentType",
          "status",
          "createdAt",
          "assignedAt",
          "onWayAt",
          "assignmentDuration",
          "rideDuration",
          "deliveryDuration",
          "preparingDuration",
          "deliveredAt",
          "productsBuyed",
        ];

        let cols = [
          { width: 20 },
          { width: 20 },
          { width: 20 },
          { width: 20 },
          { width: 20 },
          { width: 20 },
          { width: 20 },
          { width: 20 },
          { width: 20 },
          { width: 20 },
          { width: 20 },
          { width: 20 },
          { width: 20 },
          { width: 20 },
          { width: 20 },
          { width: 20 },
          { width: 20 },
          { width: 20 },
          { width: 20 },
          { width: 20 },
        ];

        let calcAverage = {
          delivered: 0,
          assignmentDuration: 0,
          rideDuration: 0,
          deliveryDuration: 0,
        };

        const data = x?.data?.map((y) => {
          if (y?.status == "delivered") {
            calcAverage.delivered++;
            calcAverage.rideDuration += y?.rideDuration;
            calcAverage.assignmentDuration += y?.assignmentDuration;
            calcAverage.deliveryDuration += y?.deliveryDuration;
          }

          return {
            [t("deliveryName")]: y?.delivery || "-",
            [t("orderNumber")]: y?.orderNumber || "-",
            [t("guestName")]: y?.name || "-",
            [t("hotelName")]: y?.hotel?.name || "-",
            [t("roomNumber")]: y?.roomNumber || "-",
            // // [t("scannedRoom")]: y?.scannedRoom || "-",
            [t("totalPrice")]: y?.totalPrice || "-",
            [t("totalPriceTry")]: y?.totalPriceTry || "-",
            [t("paymentType")]: y?.paymentType || "-",
            [t("status")]: y?.status || "-",
            [t("regionName")]: y?.region?.name || "-",
            [t("createdAt")]: y?.createdAt ? isoToDate(y.createdAt) : "-",
            [t("assignedAt")]: y?.assignedAt ? isoToDate(y?.assignedAt) : "-",
            [t("onWayAt")]: y?.onWayAt ? isoToDate(y?.onWayAt) : "-",
            [t("assignmentDuration")]: y?.assignmentDuration
              ? secondsToHms(y?.assignmentDuration)
              : "-",
            [t("rideDuration")]: y?.rideDuration
              ? secondsToHms(y?.rideDuration)
              : "-",
            [t("deliveryDuration")]: y?.deliveryDuration
              ? secondsToHms(y?.deliveryDuration)
              : "-",
            [t("preparingDuration")]: y?.preparingDuration
              ? secondsToHms(y?.preparingDuration)
              : "-",
            [t("deliveredAt")]: y?.deliveredAt
              ? isoToDate(y?.deliveredAt)
              : "-",
            [t("productsBuyed")]: y?.products?.map((z) => z?.name)?.join(","),
          };
        });

        data.push({
          [t("deliveryName")]: "",
          [t("orderNumber")]: "",
          [t("guestName")]: "",
          [t("hotelName")]: "",
          [t("roomNumber")]: "",
          // [t("scannedRoom")]: "",
          [t("totalPrice")]: "",
          [t("totalPriceTry")]: "",
          [t("paymentType")]: "",
          [t("status")]: "",
          [t("regionName")]: "",
          [t("createdAt")]: "",
          [t("assignedAt")]: "",
          [t("onWayAt")]: "",
          [t("assignmentDuration")]: secondsToHms(
            calcAverage.assignmentDuration / calcAverage.delivered
          ),
          [t("rideDuration")]: secondsToHms(
            calcAverage.rideDuration / calcAverage.delivered
          ),
          [t("deliveryDuration")]: secondsToHms(
            calcAverage.deliveryDuration / calcAverage.delivered
          ),
          [t("preparingDuration")]: secondsToHms(
            calcAverage.preparingDuration / calcAverage.delivered
          ),
          [t("deliveredAt")]: "",
          [t("productsBuyed")]: "",
        });
        console.log("* * * handleGetDeliveryReportWithDate : ", {
          data,
          calcAverage,
        });

        exportToCSV({
          apiData: data,
          fileName: `deliveryReports-from-${QueryString.from || "now"}-to-${QueryString.to || "now"
            }`,
          head,
          cols,
        });
      })
      .catch((err) => {
        console.log({ err });
      });
  };
  // ---------------------------------------------- breadcrumbs
  const breadcrumbs = [
    { title: "Dashboard", link: routes.dashboard.base },
    { title: COMPONENT_NAMES[0], link: newRoute.base },
    { title: "List" },
  ];
  // ----------------------------------------------------------------------------- Form
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
    control,
    reset,
  } = useForm({
    resolver: yupResolver(validation()),
    mode: "onSubmit",
  });
  // ---------------------------------------------- EFFECTS

  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  useEffect(() => {
    dispatch(actionBreadcrumbs.set(breadcrumbs));
    refetch();

    // const data = [
    //   {
    //     otel_uid: "0",
    //     unvan: "0",
    //     toplam_ziyaret_sayisi: "0",
    //     toplam_siparis_adet: "0",
    //     katalog_ziyaret_adedi: "0",
    //     katalog_siparis_adedi: "0",
    //   },
    //   {
    //     otel_uid: "2",
    //     unvan: "3",
    //     toplam_ziyaret_sayisi: "3",
    //     toplam_siparis_adet: "0",
    //     katalog_ziyaret_adedi: "0",
    //     katalog_siparis_adedi: "0",
    //   },
    // ];

    // exportToCSV({ apiData: data, fileName: "aa", head })

    // const fileName = 'download'
    // const exportType = 'xls'   //exported type could be text, json, csv, xls, xml
    // exportFromJSON({ data, fileName, exportType ,})
  }, []);

  const { t } = useTranslation();

  const tableOptions = tableData({
    onClick: clickAction,
    newRoute,
    isBranch,
    isHotel,
    isAdmin,
    disable: {
      delete: true,
      ...(!validPermission && {
        edit: !permissions?.edit,
        unassignDelivery: !permissions?.unassignDelivery,
        products: !permissions?.delete,
      }),
    },

    active: {
      print: true,
      Component: Factor,
      manuel: {
        icon: "mdi mdi-settings",
        class: "bg-danger",
        onClick: (data) => setShowInfoModal(data),
      },
      // ...(isAdmin && {
      //   manuel: {
      //     icon: 'mdi mdi-source-branch',
      //     class: 'bg-success',
      //     label: "change branch",
      //     onClick: (data) => {
      //       setShow(data)
      //     }
      //   }
      // })
    },
  });

  //  ----------------------------------------- LOGS ----------------------------------------- //
  console.log({ checkList, data }, "checkList");

  return (
    <div>
      <ShowModal
        {...{
          setModal: setShowInfoModal,
          show: showInfoModal,
          isBranch,
          isHotel,
          isAdmin,
          onClick: clickAction,
          permissions,
          validPermission,
        }}
      />

      <NoteForStatusCancel
        {...{
          setModal: (x) => setModalStatusCancel((p) => ({ ...p, show: x })),
          show: modalStatusCancel?.show,
          onSubmit: (x) => actionsOperation({ ...modalStatusCancel, note: x }),
        }}
      />
      <Modal
        // size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showModal}
        onHide={() => setShowModal(false)}
        style={{ zIndex: 9999999999, maxWidth: 500, margin: "auto" }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Sipariş Sahibi Kurye</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form
            onSubmit={handleSubmit(onWayStatusAction)}
            id={"form-container "}
            noValidate
          >
            <DeliveriesSelector
              {...{
                name: fieldNames.delivery,
                register,
                label: "delivery",
                errors,
                control,
              }}
            />
            <Button
              block
              type="success"
              htmlType="submit"
              className={`btn btn-success btn-sm`}
            >
              {t("publish")}
            </Button>
          </form>
        </Modal.Body>
      </Modal>
      <PageTitle
        title={t("listsAuthor", { author: t(COMPONENT_NAMES[0]) })}
        breadcrumbs={breadcrumbs}
      />
      <TableComponent
        {...{
          isHotel,
          QueryString,
          data: data?.result,
          tableOptions,
          loading: isLoading,
          search: {
            placeholder: t("search"),

            defaultValue: QueryString.search_text,
          },
          title: `${COMPONENT_NAMES[1]}`,
          // button: button,
          count: data.total,
          pagination: {
            activePage: page,
            pages: Math.ceil(data.total / limit),
            limited: 2,
          },
          checkbox: {
            state: checkList,
            setState: setCheckList,
          },
          actions: {
            onClick: handleAction,
          },
          status: STATUSES,
          dating: isAdmin
            ? {
              buttons: [
                ...(validPermission || permissions?.statistics
                  ? [
                    {
                      title: t("statics"),
                      onClick: handleGetStatistics,
                    },
                  ]
                  : []),
                ...(validPermission || permissions?.statistics
                  ? [
                    {
                      title: t("static-hotels"),
                      onClick: handleGetStatisticHotels,
                    },
                  ]
                  : []),
                ...(validPermission || permissions?.statistics
                  ? [
                    {
                      title: t("static-rooms"),
                      onClick: handleGetStatisticRooms,
                    },
                  ]
                  : []),
                ...(validPermission || permissions?.statistics
                  ? [
                    {
                      title: t("staticsDetailed"),
                      onClick: handleGetStatisticsV2,
                    },
                  ]
                  : []),
                ...(validPermission || statisticsPermissions?.list
                  ? [
                    {
                      title: t("orders"),
                      onClick: handleGetFullDataWithDate,
                    },
                  ]
                  : []),
                ...(validPermission || permissions?.products
                  ? [
                    {
                      title: t("detailedOrders"),
                      onClick: handleGetDetailedOrdersWithDate,
                    },
                  ]
                  : []),
                ...(validPermission || permissions?.deliveryStatistics
                  ? [
                    {
                      title: t("deliveryReport"),
                      onClick: handleGetDeliveryReportWithDate,
                    },
                  ]
                  : []),
              ],
              // button: {
              //   onClick: handleGetStatistics,
              // },
              // buttonFull: {
              //   onClick: handleGetFullDataWithDate,
              // }
            }
            : true,
        }}
      />

      <BranchModal {...{ show, setShow, refetchKey: `${API_NAME}_get` }} />
    </div>
  );
};

export default List;
