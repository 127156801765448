import { delay, fork, take, call, put, cancel, all, takeEvery, select } from "redux-saga/effects";

import io from "socket.io-client";
import { eventChannel } from "redux-saga";
import actionTypes from "redux/store/socket/actionTypes";
import actions from "redux/store/socket/action";

import api from "operations/network/api";
import orderAction from "containers/Order/redux/store/action";
import orderActionTypes from "containers/Order/redux/actionTypes";
import SocketToastComponent from "components/SocketToastComponent";
import toastify from "lib/toastify";
// import { QueryClient } from "react-query";
import { API_NAME } from "./../../containers/Order/enum";
import { queryClient } from "./../../index";
export function connect(token) {
	// console.log({ token });
	const socketConnection = io(api.host + "/orders?authorization=" + token, {
		transports: ["websocket"],
		rejectUnauthorized: false,
		forceNew: true,

		// origin: "http://localhost:3000",

		// query: {
		//   aaaaa: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6Im1vcmFkaS5tb2p0YWJhMjY3NEBnbWFpbC5jb20iLCJyb2xlIjoiYWRtaW4iLCJpYXQiOjE2Njk2MTkwNzgsImV4cCI6MTY3MjIxMTA3OH0.ZgmCGrYMW1jQM-S_CEriJfqoYG-_9fZynDb_ReYHpeI"
		// },

		// extraHeaders: {
		//   "aaaaaaa": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6Im1vcmFkaS5tb2p0YWJhMjY3NEBnbWFpbC5jb20iLCJyb2xlIjoiYWRtaW4iLCJpYXQiOjE2Njk2MTkwNzgsImV4cCI6MTY3MjIxMTA3OH0.ZgmCGrYMW1jQM-S_CEriJfqoYG-_9fZynDb_ReYHpeI",
		//   "moj": "mat"
		// },
		// headers: {
		//   "authorization": token

		// }
		// auth: {
		//   aaaaaaaaaaaaaaa: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6Im1vcmFkaS5tb2p0YWJhMjY3NEBnbWFpbC5jb20iLCJyb2xlIjoiYWRtaW4iLCJpYXQiOjE2Njk2MTkwNzgsImV4cCI6MTY3MjIxMTA3OH0.ZgmCGrYMW1jQM-S_CEriJfqoYG-_9fZynDb_ReYHpeI"
		// },
		// extraHeaders: {
		//   authorization: token
		// },
		// auth: {
		//   authorization: token
		// },
		// transportOptions: {
		//   polling: {
		//     extraHeaders: { authorization: token }
		//   },
		// }
	});

	return new Promise((resolve) => {
		socketConnection.on("connect", () => {
			console.log("CONNECT", socketConnection.connected);
			resolve(socketConnection);
		});
		socketConnection.on("disconnect", (data) => {
			console.log("DISCONNECTED");
		});
		socketConnection.on("reconnect", (data) => {
			console.log("RECONNECT");
		});
	});
}

// function subscribe(socket) {
//     return eventChannel((emit) => {
//         console.log({ emit });

//         const handler = (data) => {
//             console.log("Data in emitter ->>>>>>>", data);
//             emit(data);
//         };

//         socket.on("connect", handler.bind(null, "Connected!"));
//         socket.on("welcome", handler);
//         // socket.on('disconnect', handler);

//         return () => {
//             socket.off("welcome", handler);
//         };
//     });
// }
function subscribe(socket) {
	return eventChannel((emit) => {
		// console.log({ emit });

		const handler = (data) => {
			console.log("Data in emitter ->>>>>>>", data);
			emit(data);
		};

		socket.on("connect", handler.bind(null, "Connected!"));
		socket.on("welcome", handler);
		// console.log("socket listening on NewAvatar");
		socket.on("new_order", (e) => handler({ ...e, isNewOrder: true }));
		socket.on("disconnect", handler);

		return () => {
			socket.off("welcome", handler);
			socket.off("disconnect", handler);
			socket.off("serverStatus", handler);
		};
	});
}
let interval;

export function* socketConnectSaga(data) {
	// console.log("socket initial ->>>>>>>", data);
	const state = yield select((state) => state.token);
	const token = state.data?.access_token;
	const socket = yield call(connect, token);
	clearInterval(interval);

	// console.log({ socket });

	if (socket) {
		yield put(actions.receive(socket));

		const keepAliveInterval = 30000;
		// Function to send a keep-alive message
		function sendKeepAlive(socket) {
			console.log("* * * socket Keep alive message socket", { socket });
			console.log("* * * socket Keep alive message socket", { readyState: socket.connected, OPEN: WebSocket.OPEN });
			if (socket.connected) {
				console.log("* * * socket Keep alive message socket", new Date());
				// socket.send('* * * socket Keep alive message');
				socket.emit("keepAlive", "Keep alive message");
			}
		}

		// Set up an interval to send keep-alive messages
		interval = setInterval(() => {
			sendKeepAlive(socket);
		}, keepAliveInterval);
	}

	const channel = yield call(subscribe, socket);

	// orderActionTypes.RUN_EVENTS
	yield put(orderAction.socket(socket));
	console.log("* * * socketConnectSaga", { channel });
	try {
		while (true) {
			let event = yield take(channel);

			console.log("* * * socketConnectSaga", { event });
			if (event.isNewOrder) {
				toastify.info({
					title: <SocketToastComponent />,
					autoClose: false,
					closeOnClick: false,
				});
				// const queryClient = new QueryClient();
				queryClient.invalidateQueries(`${API_NAME}_get`);
				// yield put(actions.initialSuccess(socket));
			}

			// if (event.data.payload && event.data.messageType && event.data.messageType == "serverStatus") {
			//   // yield put(SocketAction.updateServerStatus(event.data.payload));
			// }
			// if (event?.data?.messageType === "NewAvatar") {
			//     yield put(
			//         getUserAvatarAction.getUserAvatarReceive(event.data.payload)
			//     );
			// }
		}
	} catch (error) {
		console.log("Error in saga chanel ->>>>>>>", error);
	}
}

export default function* rootSaga() {
	yield all([takeEvery(actionTypes.REQUEST, socketConnectSaga)]);
}
