// import { ORDER_STATUS_OBJECT, ORDER_STATUS_DATA } from "enumerations";
// import { Children } from "react";
// import { Dropdown } from "react-bootstrap";
// import { useTranslation } from "react-i18next";
// // {...(ORDER_STATUS_OBJECT[param.status] && ORDER_STATUS_OBJECT[param.status])}
// const OrderStatusButton = ({ param, onClick, isBranch, isHotel }) => {
//   const { t } = useTranslation();

//   const { object, array } = ORDER_STATUS_DATA(isBranch, isHotel);
//   let foundStatus = object[param.status];
//   console.log("found status ===>", { foundStatus, array });
//   return (
//     <Dropdown>
//       <Dropdown.Toggle id="dropdown-basic" className="btn-sm filter-sort mr-3" variant={foundStatus?.color}>
//         <span>{t(foundStatus?.label)}</span>
//       </Dropdown.Toggle>

//       <Dropdown.Menu className="dropDown-menu-custom">
//         {Children.toArray(
//           array?.map((item) =>
//             !item?.isDisabled ? (
//               <Dropdown.Item
//                 key={item.label + "_STATUS"}
//                 onClick={
//                   item.value === param?.isActive
//                     ? ""
//                     : item?.isDisabled || isHotel
//                     ? null
//                     : () => onClick({ data: param, status: item }, console.log("item ====>", item))
//                 }
//                 className="dropDown-item-custom"
//                 active={item.value === param?.isActive}
//                 disabled={item?.isDisabled || isHotel}
//               >
//                 {t(item?.label)}
//               </Dropdown.Item>
//             ) : (
//               ""
//             )
//           )
//         )}
//       </Dropdown.Menu>
//     </Dropdown>
//   );
// };

// export default OrderStatusButton;


import { ORDER_STATUS_DATA } from "enumerations";
import { Children } from "react";
import { Dropdown, OverlayTrigger } from "react-bootstrap";
import { useTranslation } from "react-i18next";
// {...(ORDER_STATUS_OBJECT[param.status] && ORDER_STATUS_OBJECT[param.status])}

const TooltipComponent = ({ data }) => {
  const { t } = useTranslation();
  return (
    <div className="bg-white p-2 mr-2 border rounded">
      <ul>
        {
          data?.map((x, i) => {
            return (
              <li key={`tooltip-item-${x?.branch?.name}-${x?.status}-${i}`}>
                <small> {x?.branch?.name}</small> :<span>{t(x?.status)} </span>
              </li>
            )
          })
        }
      </ul>

    </div>
  )
}
const OrderStatusButton = ({ param, onClick, isBranch, isHotel, isAdmin, disabled }) => {
  const { t } = useTranslation();

  const { object, array } = ORDER_STATUS_DATA(isBranch, isHotel);
  let foundStatus = object[param.status];
  console.log("found status ===>", { foundStatus, array });
  return (
    isAdmin && param?.branchStatuses?.length ? <OverlayTrigger placement="left" overlay={TooltipComponent({ data: param?.branchStatuses })}  >
      <Dropdown >
        <Dropdown.Toggle id="dropdown-basic" className="btn-sm filter-sort mr-3" variant={foundStatus?.color} disabled={disabled?.edit}>
          <span>{t(foundStatus?.label)}</span>
        </Dropdown.Toggle>

        <Dropdown.Menu className="dropDown-menu-custom">
          {Children.toArray(
            array?.map((item) =>
              !item?.isDisabled ? (
                <Dropdown.Item
                  key={item.label + "_STATUS"}
                  onClick={
                    item.value === param?.isActive
                      ? ""
                      : item?.isDisabled //|| isHotel
                        ? null
                        : () => onClick({ data: param, status: item }, console.log("item ====>", item))
                  }
                  className="dropDown-item-custom"
                  active={item.value === param?.isActive}
                  disabled={item?.isDisabled
                    // || isHotel
                  }
                >
                  {t(item?.label)}
                </Dropdown.Item>
              ) : (
                ""
              )
            )
          )}
        </Dropdown.Menu>
      </Dropdown>
    </OverlayTrigger> :
      <Dropdown>
        <Dropdown.Toggle id="dropdown-basic" className="btn-sm filter-sort mr-3" variant={foundStatus?.color} disabled={disabled?.edit}>
          <span>{t(foundStatus?.label)}</span>
        </Dropdown.Toggle>

        <Dropdown.Menu className="dropDown-menu-custom">
          {Children.toArray(
            array?.map((item) =>
              !item?.isDisabled ? (
                <Dropdown.Item
                  key={item.label + "_STATUS"}
                  onClick={
                    item.value === param?.isActive
                      ? ""
                      : item?.isDisabled //|| isHotel
                        ? null
                        : () => onClick({ data: param, status: item }, console.log("item ====>", item))
                  }
                  className="dropDown-item-custom"
                  active={item.value === param?.isActive}
                  disabled={item?.isDisabled
                    // || isHotel
                  }
                >
                  {t(item?.label)}
                </Dropdown.Item>
              ) : (
                ""
              )
            )
          )}
        </Dropdown.Menu>
      </Dropdown>
  );
};

export default OrderStatusButton;
