import { convertorArrayOption } from "utils/convertor";
import placeholder from "assets/images/placeholder.png";

export const LOCAL_STORAGE_TEMPLATE = {
  token: "token",
};

export const REGISTER_TYPE = {
  phone: "PHONE_NUMBER",
  email: "EMAIL",
};

// export const ERRORS = {
//   isRequire: (author) => `${author} is require`,
//   isNotValid: (author) => `${author} is not valid`,
//   pleaseEnterYour: (author) => `please enter your ${author}`,
//   pleaseEnter: (author) => `please enter ${author}`,
//   mustBe: (author, kind) => `${author} must be ${kind}`,
//   pleaseSelectYour: (author) => `please select your ${author}`,
// };

export const ERRORS = {
  isRequire: (author) => ({ text: "errors.is-require", params: { author } }),
  isNotValid: (author) => ({ text: "errors.is-not-valid", params: { author } }),
  pleaseEnterYour: (author) => ({
    text: "errors.please-enter-your",
    params: { author },
  }),
  pleaseEnter: (author) => ({
    text: "errors.please-enter",
    params: { author },
  }),
  mustBe: (author, kind) => ({
    text: "errors.must-be",
    params: { author, kind },
  }),
  pleaseSelectYour: (author) => ({
    text: "errors.please-select-your",
    params: { author },
  }),
  min: (author) => ({ text: "errors.min", params: { author } }),
  max: (author) => ({ text: "errors.max", params: { author } }),
};

export const tableDisplayed = [
  { label: 10, value: 10 },
  { label: 20, value: 20 },
  { label: 40, value: 40 },
  // { label: 80, value: 80 },
  // { label: 160, value: 160 },
  // { label: 240, value: 240 },
];

export const baseImageUrl = () => {
  return {
    main: "https://ds447a7iqja4o.cloudfront.net/images/",
    resized: "https://dx0uqd4klfo90.cloudfront.net/resized-images/",
  };
};

// ----------------------------------------

export const PAYMENT_PERIOD = [
  { label: "1", value: 1 },
  { label: "3", value: 3 },
  { label: "6", value: 6 },
  { label: "12", value: 12 },
];

export let PAYMENT_PERIOD_OBJECT = {};

convertorArrayOption(PAYMENT_PERIOD, PAYMENT_PERIOD_OBJECT);
// ----------------------------------------

export const STATUSES = [
  // { label: "All Status", value: undefined },
  { label: "active", value: true, color: "success" },
  { label: "inactive", value: false, color: "danger" },
  // { label: "DELETE", value: "2" },
  // { label: "BLOCK", value: "3" },
  // { label: "BAN", value: "4" },
];

export let STATUSES_OBJECT = {};

convertorArrayOption(STATUSES, STATUSES_OBJECT);
// ----------------------------------------

export const STATUSES_BASE = [
  { value: "rejected", label: "rejected", color: "danger" },
  { value: "waiting", label: "waiting", color: "warning" },
  { value: "approved", label: "approved", color: "success" },
];

export let STATUSES_BASE_OBJECT = {};

convertorArrayOption(STATUSES_BASE, STATUSES_BASE_OBJECT);
// ----------------------------------------

export const IS_WORKING = [
  // { label: "All Status", value: undefined },
  { label: "Aktif", value: true },
  { label: "Pasif", value: false },
  // { label: "DELETE", value: "2" },
  // { label: "BLOCK", value: "3" },
  // { label: "BAN", value: "4" },
];
// ----------------------------------------

export let IS_WORKING_OBJECT = {};

convertorArrayOption(IS_WORKING, IS_WORKING_OBJECT);

// ----------------------------------------

export const ROLES = [
  { label: "admin", value: "admin" },
  { label: "branch", value: "branch" },
  { label: "hotel", value: "hotel" },
];

export let ROLES_OBJECT = {};

convertorArrayOption(ROLES, ROLES_OBJECT);

// ----------------------------------------

export const SORT_OPTION = [
  { label: "newest", value: "desc" },
  { label: "oldest", value: "asc" },
];

export const SORT_OPTION_OBJECT = {};

convertorArrayOption(SORT_OPTION, SORT_OPTION_OBJECT);

// ----------------------------------------

export const INVOICE_DATA = [
  { value: "formal", label: "formal" },
  { value: "in_formal", label: "inFormal" },
];

export const INVOICE_DATA_OBJECT = {};

convertorArrayOption(INVOICE_DATA, INVOICE_DATA_OBJECT);

// ----------------------------------------

export const DRINK_DATA = [
  { value: "papyon", label: "Papyon" },
  { value: "hotel", label: "Hotel" },
];
export const DRINK_DATA_OBJECT = {};

convertorArrayOption(DRINK_DATA, DRINK_DATA_OBJECT);

// ----------------------------------------

export const OPERATION = [
  { value: "updatePercentOfPrice", label: "Update Percent Of Price" },
  { value: "updateToProductPrice", label: "Update To Product Price" },
  { value: "updateUnitOfPrice", label: "Update Unit Of Price" },
];
export const OPERATION_OBJECT = {};

convertorArrayOption(OPERATION, OPERATION_OBJECT);

// ----------------------------------------

export const OPERATION_SAID = [
  { value: "increase", label: "Increase" },
  { value: "decrease", label: "Decrease" },
];
export const OPERATION_SAID_OBJECT = {};

convertorArrayOption(OPERATION_SAID, OPERATION_SAID_OBJECT);
// ----------------------------------------

export const PLACEHOLDER_IMAGE = placeholder;

export const LANGUAGE_DATA = [
  { value: "en", label: "English" },
  { value: "tr", label: "Turkey" },
];
export const LANGUAGE_DATA_OBJECT = {};

convertorArrayOption(LANGUAGE_DATA, LANGUAGE_DATA_OBJECT);
// ----------------------------------------

export const PAYMENT_DATA = [
  { value: "online", label: "online" },
  { value: "card", label: "card" },
  { value: "cash", label: "cash" },
];

export const PAYMENT_DATA_OBJECT = {};

convertorArrayOption(PAYMENT_DATA, PAYMENT_DATA_OBJECT);
// ----------------------------------------

export const ORDER_STATUS_DATA = (branch) => {
  const ARRAY = [
    { value: "refund", label: "refund", color: "danger", isDisabled: branch },
    {
      value: "canceled",
      label: "canceled",
      color: "danger",
      isDisabled: branch,
    },
    {
      value: "payment_success",
      label: "paymentSuccess",
      color: "success",
      isDisabled: branch,
    },
    {
      value: "payment_failed",
      label: "paymentFailed",
      color: "danger",
      isDisabled: branch,
    },
    {
      value: "preparedCancel",
      label: "preparedCancel",
      color: "danger",
      isDisabled: branch,
    },
    {
      value: "served",
      label: "Served",
      color: "warning",
      isDisabled: branch,
    },
    { value: "waiting", label: "waiting", color: "warning" },
    { value: "sended", label: "sended", color: "success", isDisabled: branch },
    { value: "preparing", label: "preparing", color: "info" },
    { value: "delivered", label: "delivered", color: "success", isDisabled: branch },
    { value: "on_way", label: "onWay", color: "info", isDisabled: branch },
    { value: "unknown", label: "Need To Be Checked", color: "dark", isDisabled: branch },
  ];

  const OBJECT = {};

  convertorArrayOption(ARRAY, OBJECT);

  return {
    object: OBJECT,
    array: ARRAY,
  };
};

export const ORDER_STATUS = [
  { value: "refund", label: "refund", color: "danger" },
  { value: "canceled", label: "canceled", color: "danger" },
  { value: "payment_success", label: "paymentSuccess", color: "success" },
  { value: "payment_failed", label: "paymentFailed", color: "danger" },
  { value: "preparedCancel", label: "paymentFailed", color: "danger" },
  { value: "waiting", label: "waiting", color: "warning" },
  { value: "sended", label: "sended", color: "success" },
  { value: "preparing", label: "preparing", color: "info" },
  { value: "delivered", label: "delivered", color: "success" },
  { value: "on_way", label: "onWay", color: "info" },
  { value: "served", label: "Served", color: "warning" },
];

export const ORDER_STATUS_OBJECT = {};

convertorArrayOption(ORDER_STATUS, ORDER_STATUS_OBJECT);

// ----------------------------------------

export const USER_TYPES = [
  { label: "super admin", value: "super_admin" },
  { label: "admin", value: "admin" },
  { label: "branch", value: "branch" },
  { label: "hotel", value: "hotel" },
];

export let USER_TYPES_OBJECT = {};

convertorArrayOption(USER_TYPES, USER_TYPES_OBJECT);

// ----------------------------------------
export const CURRENCY_DATA = [
  { value: "usd", label: "USD" },
  { value: "eur", label: "EUR" },
  { value: "try", label: "TRY" },
];

export const CURRENCY_OBJECT = {};

convertorArrayOption(CURRENCY_DATA, CURRENCY_OBJECT);

// ----------------------------------------

export const CATEGORY_DATA = [
  { label: "Diğer", value: "other" },
  { label: "Sıcak İçecek", value: "hot_drink" },
  { label: "Soğuk İçecek", value: "cold_drink" },
  { label: "Alkol", value: "alcohol" },
  { label: "Banner", value: "banner" },
];

export const CATEGORY_OBJECT = {};

convertorArrayOption(CATEGORY_DATA, CATEGORY_OBJECT);

export const PERMISSIONS = {
  // -------------------------------
  ADMIN: "ADMIN",
  // -------------------------------
  USER: "USER",
  // -------------------------------
  ROLE: "ROLE",
  // -------------------------------
  BLOG: "BLOG",
  // -------------------------------
  NEWSLETTER: "NEWSLETTER",
  // -------------------------------
  MEDIA: "MEDIA",
  // -------------------------------
  PERMISSION: "PERMISSION",
  // -------------------------------
  SETTING: "SETTING",
  // -------------------------------
};
