import { forwardRef, useEffect, useRef, useState } from "react";
import "./index.css";
import moment from "moment";
import { changePriceFormat } from "utils/calculators";
import { useTranslation } from "react-i18next";
import isoToDate from "utils/isoToDate";

// import axios from "axios";

const Factor = ({ propData, ...props }, ref) => {
  const paperRef = useRef();

  let { isBranch, isAdmin } = propData || {};
  // isBranch = true
  // isAdmin = false
  // const [Style, setStyle] = useState()
  let Style;
  const { t } = useTranslation();
  console.log("factor props", props);
  console.log("isBranch", isBranch);

  const pxToMm = (px) => {
    let div = document.createElement("div");
    div.style.display = "block";
    div.style.height = "1mm";
    document.body.appendChild(div);
    return Math.floor(px / div.offsetHeight);
    // return Math.floor(px / 0.2645833333);
    // return (px * 25.4) / DPI
  };

  const mmToPx = (mm) => {
    let div = document.createElement("div");
    div.style.display = "block";
    div.style.height = "1mm";
    document.body.appendChild(div);
    return Math.floor(mm * div.offsetHeight);
  };
  const reducer = (accumulator, currentValue) =>
    accumulator + currentValue.quantity;
  const total = props?.products.reduce(reducer, 0);
  console.log({ total });
  const inputWidthMm = pxToMm(mmToPx(53));
  const inputHeightMm = pxToMm(total * 22 + 450);
  // console.log({ inputHeightMm, inputWidthMm });
  // // props?.products

  const GlobalStyler = () => (
    <style>{`

    @media print {
      @page {
        size: 80mm ${inputHeightMm}mm;
    }
    
    }


       
  `}</style>
  );

  return (
    <div>
      <div
        className="factor border py-2"
        ref={ref}
        style={{
          width: "70mm",
          background: "white",
          fontWeight: "900",
        }}
      >
        <div className="" ref={paperRef}>
          <div className="pt-2">
            <h6
              className="title text-center"
              style={{
                fontSize: "16px",
                fontWeight: "900",
              }}
            >
              {/* Papyon Room Service Solutions */}
              {isAdmin
                ? props?.hotel?.branch?.name
                : "Papyon Room Service Solutions"}
            </h6>
          </div>

          <div className="px-3 py-3">
            <div className="row-label mb-2">
              <label>{"Order Number:"}</label>
              <h7>{props?.orderNumber}</h7>
            </div>

            {!isBranch ? (
              <div className="row-label  mb-2">
                <label
                  style={{
                    fontSize: "17px",
                    marginRight: "20px",
                  }}
                >
                  Ad Soyad :
                </label>
                <h7>{props.name}</h7>
              </div>
            ) : (
              ""
            )}
            {!isBranch ? (
              <div className="row-label mb-2">
                <label
                  style={{
                    fontSize: "17px",
                    marginRight: "20px",
                  }}
                >
                  Hotel :
                </label>
                <h7>{props?.hotel?.name}</h7>
              </div>
            ) : (
              ""
            )}

            {!isBranch ? (
              <div className="row-label mb-2">
                <label
                  style={{
                    fontSize: "17px",
                    marginRight: "20px",
                  }}
                >
                  Room Number :
                </label>
                <h7>{props?.roomNumber}</h7>
              </div>
            ) : (
              ""
            )}

            {!isBranch ? (
              <div
                className="row-label mb-2"
                style={{
                  display: "flex",
                  marginBottom: "10px",
                }}
              >
                <label
                  style={{
                    fontSize: "17px",
                    marginRight: "20px",
                  }}
                >
                  Payment :
                </label>
                <h7>{t(props?.paymentType)}</h7>
              </div>
            ) : (
              ""
            )}
          </div>

          <div
            className="border-bottom "
            style={{
              borderBottom: "1px solid #ccc",
            }}
          ></div>

          <div
            className="px-3 py-4"
            style={{
              padding: "10px 10px",
            }}
          >
            {props?.products?.map((x) => {
              console.log({ x });
              return new Array(x.quantity).fill({}).map((_, i) => (
                <div
                  className="row-label mb-2 d-flex justify-content-between"
                  key={changePriceFormat(x?.product?.name + x?.price)}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "10px",
                  }}
                >
                  <label>{x?.product?.name} </label>
                  {!isBranch ? (
                    <h7
                      style={{
                        marginLeft: "20px",
                      }}
                    >
                      <small
                        style={{
                          fontSize: "12px",
                        }}
                      >
                        £
                      </small>
                      {" "}
                      {changePriceFormat(x?.price / x.quantity)}{" "}

                    </h7>
                  ) : (
                    ""
                  )}
                </div>
              ));
            })}
          </div>

          <div
            className="border-bottom "
            style={{
              borderBottom: "1px solid #ccc",
            }}
          ></div>

          <div
            className="px-3 py-4 text-center"
            style={{
              textAlign: "center",
              padding: "10px 10px",
            }}
          >
            <div
              className="row-label mb-2"
              style={{
                marginBottom: "5px",
                display: "flex",
              }}
            >
              <label>Note :</label>
              <p>{props.note}</p>
            </div>

            {!isBranch ? (
              <div
                className="row-label  mb-2"
                style={{
                  marginBottom: "5px",
                  display: "flex",
                  // justifyContent: "space-between",
                }}
              >
                <label>Service Charge:</label>
                <h7>
                  <small
                    style={{
                      fontSize: "12px",
                    }}
                  >
                    £
                  </small>
                  {" "}

                  {changePriceFormat(props.serviceCharge)}
                </h7>
              </div>
            ) : (
              ""
            )}

            {!isBranch ? (
              <div
                className="row-label  mb-2"
                style={{
                  marginBottom: "5px",
                  display: "flex",
                  // justifyContent: "space-between",
                }}
              >
                <label>TOTAL PRICE:</label>
                <h7>
                  <small
                    style={{
                      fontSize: "12px",
                    }}
                  >
                    £
                  </small>
                  {" "}
                  {changePriceFormat(props.totalPrice)}
                </h7>
              </div>
            ) : (
              ""
            )}
            {/* <div
              className="row-label  mb-2"
              style={{
                marginBottom: "5px",
                display: "flex",
                // justifyContent: "space-between",
              }}
            >
              <label>TOTAL DOLLAR:</label>
              <h7>
                {changePriceFormat(props.totalPriceUsd)}
                <small
                  style={{
                    fontSize: "12px",
                  }}
                >
                  $
                </small>{" "}
              </h7>
            </div> */}

            {/* <div
              className="row-label  mb-2"
              style={{
                marginBottom: "5px",
                display: "flex",
                // justifyContent: "space-between",
              }}
            >
              <label>TOTAL TL:</label>
              <h7>
                {changePriceFormat(props.totalPriceTry)}
                <small
                  style={{
                    fontSize: "12px",
                  }}
                >
                  ₺
                </small>{" "}
              </h7>
            </div> */}
            {/* 
            {!isBranch ? (
              <div
                className="row-label  mb-2"
                style={{ marginBottom: "5px", display: "flex" }}
              >
                <label>Type:</label>
                <h7>
                  {changePriceFormat(props.paymentCurrency)}
                  <h7>
                    {props.paymentCurrency === "try" && "Turkish Lira"}
                    {props.paymentCurrency === "usd" && "Dollar"}
                    {props.paymentCurrency === "eur" && "Euro"}
                  </h7>
                </h7>
              </div>
            ) : (
              ""
            )} */}

            <div
              className="border-bottom "
              style={{
                borderBottom: "1px solid #ccc",
              }}
            ></div>

            {/* <div
              className="row-label mt-2"
              style={{
                marginBottom: "5px",
                display: "flex",
                // justifyContent: "space-between",
                textAlign: "center",
              }}
            >
              <h7>1 $ = {(props?.dollar * 1.08).toFixed(2)}</h7>
            </div>
            <div
              className="row-label mt-2"
              style={{
                marginBottom: "5px",
                display: "flex",
                // justifyContent: "space-between",
                textAlign: "center",
              }}
            >
              <h7>1£ = {(props?.euro * 1.08).toFixed(2)}</h7>
            </div> */}

            <div
              className="row-label mb-2"
              style={{
                marginBottom: "5px",
                display: "flex",
                // justifyContent: "space-between",
                textAlign: "center",
              }}
            >
              <h7>Date :{isoToDate(props?.createdAt)}</h7>
            </div>

            <div
              className="row-label mb-2"
              style={{
                marginBottom: "5px",
                display: "flex",
                // justifyContent: "space-between",
                textAlign: "center",
              }}
            >
              <h7>It Has No Financial Value</h7>
            </div>
          </div>
        </div>
      </div>
      <GlobalStyler />
    </div>
  );
};

export default forwardRef(Factor);
