import { put, call, takeEvery, all, delay } from "redux-saga/effects";
import { changeError } from "utils/errors";
import actionTypes from "../actionTypes";
import action from "../store/action";
// import actionAccessToken from "redux/store/accessToken/action";
// import actionRefreshToken from "redux/store/refreshToken/action";
import network from "lib/network";
import { tokenMsAge } from "utils/cookies";
import Cookies from 'universal-cookie';

import api from "operations/network/api";
import axiosConfig from "lib/axiosConfig";
import actionUser from "redux/store/admin/action";
import cLog from "utils/cLog";
import actionToken from "redux/store/token/action";

function* postSaga({ params }) {
  console.log({ params });

  try {
    const res = yield call(network.post, { url: api.verify, params });
    // const res = yield call(axiosConfig.post, { url: api.verify, params });
    console.log({ res });

    let accessToken = res.data.access_token;
    // let refreshToken = res.data.result.refreshToken;
    const dateNow = new Date();
    let exTimeAccess = Math.abs(Math.floor(tokenMsAge(accessToken)?.s));
    // let exTimeRefresh = Math.abs(Math.floor(tokenMsAge(refreshToken)?.s));
    yield put(actionToken.receive(res.data));

    console.log({ accessToken, exTimeAccess, dateNow });
    const cookies = new Cookies();
    // yield cookies.set("refreshToken", refreshToken, { maxAge: exTimeRefresh, path: "/" });
    yield cookies.set("accessToken", accessToken, { path: "/" });
    yield cookies.set("role", res.data.role, { path: "/" });
    // yield nookies.set(ctx, 'refreshToken', refreshToken, { maxAge: exTimeRefresh })
    // yield nookies.set(ctx, 'accessToken', accessToken)
    // , { maxAge: exTimeAccess }

    // yield put(actionAccessToken.receive(accessToken));
    // yield put(actionRefreshToken.receive(refreshToken));
    yield put(actionUser.request());

    yield put(action.receive(res.data));



    yield delay(2000);
    yield put(action.initial());
    // yield put(actionUser.initial());
  } catch (error) {
    // cLog({ error });
    yield put(action.failure(changeError(error)));
    yield delay(2000);
    yield put(action.failure());
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actionTypes.VERIFY_CODE_REQUEST, postSaga)]);
}
