import { ERRORS } from "enumerations";
import regex from "enumerations/regex";
import * as yup from "yup";

export const fieldNames = {
  price: "price",
  product: "product",
  kdv: "kdv",
  currency: "currency",
  delivery: "delivery",
  branch: "branch",
};

export const validation = (URL_ID) =>
  yup.object().shape({
    [fieldNames.price]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.price)).label(fieldNames.price),
    [fieldNames.kdv]: yup.string().default(0).required(ERRORS.pleaseEnterYour(fieldNames.kdv)).label(fieldNames.kdv),
    [fieldNames.product]: yup.object().required(ERRORS.pleaseEnterYour(fieldNames.product)).label(fieldNames.product),
    [fieldNames.currency]: yup.object().required(ERRORS.pleaseEnterYour(fieldNames.currency)).label(fieldNames.currency),
    [fieldNames.delivery]: yup.object().required(ERRORS.pleaseEnterYour(fieldNames.delivery)).label(fieldNames.delivery),
    //   [fieldNames.lastName]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.lastName)).label(fieldNames.lastName),
    //   [fieldNames.email]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.email)).label(fieldNames.email),
    //   [fieldNames.password]: yup
    //     .string()
    //     .trim()
    //     .test("", "Password must at least 8 characters, including uppercase,lowercase,number and symbol", (value) => {
    //       const re = new RegExp(regex.passwordRegExp);
    //       if (value) return ~re.test(value)
    //       else return true
    //       //  isAddress(value) && value !== ZERO_ADDRESS)
    //       // 
    //     })
    //     // .matches(regex.passwordRegExp, "Password must at least 8 characters, including uppercase,lowercase,number and symbol")
    //     .label("Password"),
    //   [fieldNames.confirm_password]: yup.string().oneOf([yup.ref("password"), null], "Passwords must match"),
    //   [fieldNames.role]: yup.object().label(fieldNames.role),
    //   [fieldNames.phoneNumber]: yup.string().label(fieldNames.phoneNumber),
    //   [fieldNames.isActive]: yup.object().label(fieldNames.isActive),
    //   // [fieldNames.type]: yup.object().required(ERRORS.pleaseSelectYour("Type")).label("Type"),
    //   // [fieldNames.image]: yup.object().nullable().required(ERRORS.pleaseSelectYour("image")).label("image"),
    // })
    // : yup.object().shape({
    //   [fieldNames.name]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.name)).label(fieldNames.name),
    //   [fieldNames.lastName]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.lastName)).label(fieldNames.lastName),
    //   [fieldNames.email]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.email)).label(fieldNames.email),
    //   [fieldNames.password]: yup
    //     .string()
    //     .trim()
    //     .matches(regex.passwordRegExp, "Password must at least 8 characters, including uppercase,lowercase,number and symbol")
    //     .required(ERRORS.pleaseEnterYour("Password"))
    //     .label("Password"),
    //   [fieldNames.confirm_password]: yup.string().oneOf([yup.ref("password"), null], "Passwords must match"),
    //   [fieldNames.role]: yup.object().label(fieldNames.role),
    //   [fieldNames.phoneNumber]: yup.string().label(fieldNames.phoneNumber),
    //   [fieldNames.isActive]: yup.object().label(fieldNames.isActive),
    // [fieldNames.name]: yup.string().required(ERRORS.pleaseEnterYour("First name")).label("name"),
    // [fieldNames.lastName]: yup.string().required(ERRORS.pleaseEnterYour("Last name")).label("LastName"),
    // [fieldNames.email]: yup.string().required(ERRORS.pleaseEnterYour("Email")).label("Email"),
    // [fieldNames.password]: yup
    //   .string()
    //   .matches(regex.passwordRegExp, "Password must at least 8 characters, including uppercase,lowercase,number and symbol")

    //   .required(ERRORS.pleaseEnterYour("Password"))
    //   .label("Password"),
    // [fieldNames.confirm_password]: yup.string().oneOf([yup.ref("password"), null], "Passwords must match"),
    // [fieldNames.role]: yup.object().label("Role"),
    // [fieldNames.phoneNumber]: yup.string().label("Mobile Number"),
    // [fieldNames.status]: yup.object().label("Status"),
    // [fieldNames.image]: yup.object().nullable().required(ERRORS.pleaseSelectYour("image")).label("image"),
    // [fieldNames.type]: yup.object().required(ERRORS.pleaseSelectYour("Type")).label("Type"),
  });
