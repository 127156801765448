import CheckedComponent from "components/CheckedComponent";
import TableAction from "components/TableAction";
import TableStatus from "components/TableStatus";
import { PLACEHOLDER_IMAGE } from "enumerations";
import routes from "operations/routing/routes";
import { Link } from "react-router-dom";

import isoToDate from "utils/isoToDate";
export const tableData = ({ onClick, newRoute, ...other }) => {
	const admin = [
		{
			header: "Status",
			Component: ({ param }) => <TableStatus {...{ param, onClick }} />,
		},
		{
			header: "Actions",
			Component: ({ param }) => <TableAction {...{ param, newRoute, onClick, ...other }} />,
		},
		{
			header: "id",
			Component: ({ param }) => param.id,
		},
		{
			header: "stock",
			Component: ({ param }) => param.stock,
		},

		{
			header: "Image",
			Component: ({ param }) => {
				return (
					<>
						<span className="d-block mb-1">
							<img src={param?.images || PLACEHOLDER_IMAGE} className="image-fluid" />
						</span>
					</>
				);
			},
		},
		{
			header: "integrationCode",
			Component: ({ param }) => param.integrationCode,
		},
		{
			header: "branchProductName",
			Component: ({ param }) => param.branchProductName,
		},
		{
			header: "allergies",
			Component: ({ param }) => param.allergies?.join(" , "),
		},
		{
			header: "name",
			Component: ({ param }) => param.name,
		},
		{
			header: "price",
			Component: ({ param }) => param.price,
		},

		{
			header: "shortDesc",
			Component: ({ param }) => param.shortDesc,
		},

		{
			header: "translations",
			Component: ({ param }) => (
				<div>
					{param.translations?.map?.((x) => {
						return (
							<div>
								{x?.name + " (" + x?.language + ")"}
								<br />
							</div>
						);
					})}
				</div>
			),
		},
		{
			header: "category",
			Component: ({ param }) => {
				const { base, edit } = routes.category;
				return param.category ? (
					<Link to={`${base + edit}/${param?.category?.id}`} target="_blank">
						{" "}
						{param.category?.name}{" "}
					</Link>
				) : (
					"Unknown"
				);
			},
		},
		{
			header: "branch",
			Component: ({ param }) => {
				const { base, edit } = routes.branch;
				return param.branch ? (
					<Link to={`${base + edit}/${param.branch?.id}`} target="_blank">
						{" "}
						{param.branch?.name}{" "}
					</Link>
				) : (
					"Unknown"
				);
			},
		},
		{
			header: "isIdVerificationRequired",
			Component: ({ param }) => {
				return <CheckedComponent checked={param.isIdVerificationRequired} />;
			},
		},
		{
			header: "medicine",
			Component: ({ param }) => {
				return <CheckedComponent checked={param.medicine} />;
			},
		},
		{
			header: "isRecommended",
			Component: ({ param }) => {
				return <CheckedComponent checked={param.isRecommended} />;
			},
		},
		{
			header: "listOrderNumber",
			Component: ({ param }) => `${param.listOrderNumber || "Unknown"}`,
		},

		{
			header: "createdAt",
			Component: ({ param }) => isoToDate(param.createdAt),
		},
	];

	const branch = [
		{
			header: "Image",
			Component: ({ param }) => {
				return (
					<>
						<span className="d-block mb-1">
							<img src={param?.images || PLACEHOLDER_IMAGE} className="image-fluid" />
						</span>
					</>
				);
			},
		},
		{
			header: "name",
			Component: ({ param }) => param.name,
		},
		{
			header: "price",
			Component: ({ param }) => param.buyPrice,
		},

		{
			header: "description",
			Component: ({ param }) => param.shortDesc,
		},
		{
			header: "Status",
			Component: ({ param }) => <TableStatus {...{ param, onClick }} />,
		},
		{
			header: "createdAt",
			Component: ({ param }) => isoToDate(param.createdAt),
		},
	];

	return other?.isBranch ? branch : admin;
};
