import { Button, Col, Modal, Row } from "react-bootstrap";
import { useEffect, useState } from 'react';
import isoToDate from "utils/isoToDate";
import Factor from "containers/Factor";
import reactDom from "react-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { fieldNames, validation } from "./validation";
import { useForm } from "react-hook-form";
import ProductSelector, { convertProductData } from "containers/Product/ProductSelector";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import CheckBox from "components/CheckBox";
import axiosClient from 'lib/axiosConfig';
import toastify from "lib/toastify";
import { useMutationCustom } from "utils/useMutationCustom";
import { API_NAME } from "containers/Order/enum";
import api from "operations/network/api";
import OrderStatusButton from "../OrderStatusButton";

const ShowModal = ({ setModal, show, onClick, permissions, validPermission, isAdmin, ...props }) => {


    const updating = async (params) => await axiosClient().patch(api[API_NAME].base + "/" + show.id, params);

    // ----------------------------------------------------------------------------- Mutation
    const onSuccessMutating = () => {
        toastify.success({ title: "success" });
        setModal(false)
    };
    const { isLoading, data, mutate } = useMutationCustom({
        url: updating,
        invalidQuery: `${API_NAME}_get`,
        onSuccess: onSuccessMutating,
    });

    const [t] = useTranslation();

    const {
        register,
        handleSubmit,
        formState: { errors },
        getValues,
        setValue,
        control,
        reset,
        watch
    } = useForm({
        resolver: yupResolver(validation),
        mode: "onSubmit",
    });

    useEffect(() => {
        if (show) reset({
            products: show?.products?.map((x) => ({
                [fieldNames.oldProduct]: convertProductData(x?.product),
                [fieldNames.newProduct]: null,
                [fieldNames.canceled]: false,
                [fieldNames.notify]: false,
            }))
        })
    }, [show])
    const onSubmitted = () => {
        const values = getValues();
        console.log({ values });
        // onSubmit(values?.note)
        // setModal(false)
        const requestData = {
            paymentType: show.paymentType,
            status: show.status,
            cancelNote: show.cancelNote,
            products: values.products.map((x) => ({
                oldProduct: x.oldProduct.value,
                newProduct: x.newProduct.value,
                canceled: x.canceled,
                notify: x.notify,
            })),
        };
        console.log("* * * requestData : ", { requestData, values, "show?.products": show?.products });
        mutate(requestData);

    }

    console.log({ show });
    const param = show

    async function printDiv() {
        var myframe = document.createElement("iframe");
        myframe.style.position = "absolute";
        myframe.style.top = "-10000px";
        document.body.appendChild(myframe);

        if (param) {
            reactDom.render(<Factor {...param} propData={props} />, myframe.contentDocument.body);
        }

        setTimeout(function () {
            myframe.focus();
            myframe.contentWindow.print();
            myframe.parentNode.removeChild(myframe);
        }, 1000);
        window.focus();
    }

    return (
        <Modal
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={show}
            onHide={() => setModal(false)}
            style={{ margin: "auto" }}
        >
            <Modal.Header closeButton>
                <Modal.Title className="d-flex w-100 align-items-center">
                    <div className="d-flex">
                        <small>
                            {`order number: `}{show?.orderNumber}
                        </small>
                        <div className="mr-4"></div>
                        <small>
                            {`created At: `}{isoToDate(show?.createdAt)}
                        </small>
                    </div>
                    <div className="ml-auto"></div>
                    <div className="d-flex">

                        <span
                            className="btn btn-success shadow btn-xs sharp pointer action-btn mdi rounded p-2 height-fit-content "
                            onClick={printDiv}
                        // onClick={() => onClick({ data: [param], status: { label: "print" } })}
                        >
                            <i className="mdi mdi-printer"></i>
                        </span>

                        <OrderStatusButton
                            {...{
                                param,
                                onClick,
                                isBranch: props.isBranch,
                                isHotel: props.isHotel,
                                disabled: !permissions?.edit || validPermission
                            }}
                        />
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    {show?.products?.map((x) => {
                        return new Array(x.quantity).fill({}).map((_, i) => (
                            <div className="col-md-4 grid-margin stretch-card" key={x._id || x.id}>
                                <div className="card">
                                    <div className="card-body">
                                        <h4 className="card-title">{x?.product?.name}</h4>
                                        <div className="media">
                                            <img src={x?.product?.images} width={45} />
                                            <div className="mr-2" />
                                            <div className="media-body">
                                                <p className="card-text">{x?.product?.shortDesc}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ));
                    })}


                </div>
                {isAdmin ?
                    <>
                        <div className="my-3">{t("setting")}</div>


                        <form onSubmit={handleSubmit(onSubmitted)}>
                            <Row>

                                {
                                    show?.products?.map((x, i) => {
                                        return (
                                            <Col xs={6} >
                                                <div className="px-3 py-4 mb-2 border bg-white">

                                                    <ProductSelector
                                                        isDisabled
                                                        {...{
                                                            Controller,
                                                            name: `products.${i}.${fieldNames.oldProduct}`,
                                                            register,
                                                            label: "oldProduct",
                                                            error: errors?.products?.[i]?.[fieldNames.oldProduct],
                                                            control,
                                                            // value: convertProductData(x?.product)

                                                        }}
                                                    />

                                                    <ProductSelector
                                                        {...{
                                                            Controller,
                                                            name: `products.${i}.${fieldNames.newProduct}`,
                                                            register,
                                                            label: "newProduct",
                                                            error: errors?.products?.[i]?.[fieldNames.newProduct],
                                                            control,
                                                        }}
                                                    />

                                                    <CheckBox
                                                        {...{
                                                            name: `products.${i}.${fieldNames.notify}`,
                                                            label: "notify",
                                                            // value: value?.[index]?.[fieldNames.user_isPhoneNotificationActive],
                                                            error: errors?.products?.[i]?.[fieldNames.notify]?.message,
                                                            // onChange: handleChangeInput,
                                                            register,
                                                            control,
                                                        }}
                                                    />

                                                    <CheckBox
                                                        {...{
                                                            name: `products.${i}.${fieldNames.canceled}`,
                                                            label: "canceled",
                                                            // value: value?.[index]?.[fieldNames.user_isPhoneNotificationActive],
                                                            error: errors?.products?.[i]?.[fieldNames.canceled]?.message,
                                                            // onChange: handleChangeInput,
                                                            register,
                                                            control,
                                                        }}
                                                    />
                                                </div>

                                            </Col>
                                        )
                                    })
                                }

                            </Row>
                            <Button disabled={isLoading} className={"d-flex align-items-center"} size="sm" type="submit">
                                <div className={"gallery-add-title"}>
                                    {t("submit")}
                                </div>

                            </Button>
                        </form>
                    </> : ""}


            </Modal.Body>
        </Modal>
    )
}

export default ShowModal;