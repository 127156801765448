import routes from "operations/routing/routes";
import { Link } from "react-router-dom";

export const tableData = ({ onClick, newRoute, ...props }) => [
  {
    header: "name",
    Component: ({ param }) => {
      const { base, edit } = routes.category;
      return param.productName ? (
        <Link to={`${base + edit}/${param.productId}`} target="_blank">
          {" "}
          {param.productName}{" "}
        </Link>
      ) : (
        "Unknown"
      );
    },
  },
  {
    header: "buyPrice",
    Component: ({ param }) => (param.buyPrice) || '-',
  },
  {
    header: "price",
    Component: ({ param }) => param.price || '-',
  },
  {
    header: "cost",
    Component: ({ param }) => param.cost || '-',
  },
  {
    header: "category",
    Component: ({ param }) => param.category || '-',
  },

  {
    header: "integrationCode",
    Component: ({ param }) => param.integrationCode || '-',
  },
  {
    header: "orderNumber",
    Component: ({ param }) => param.orderNumber || '-',
  },
];
