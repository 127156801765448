import TableAction from "components/TableAction";
import routes from "operations/routing/routes";
import { Link } from "react-router-dom";

import isoToDate from "utils/isoToDate";
import TableStatus from 'components/TableStatus';

export const tableData = ({ onClick, newRoute }) =>
  [
    {
      header: "name",
      Component: ({ param }) => param.name,
    },
    {
      header: "telegramChatId",
      Component: ({ param }) => param.telegramChatId,
    },
    {
      header: "branches",
      Component: ({ param }) => param.branches?.length,
    },
    {
      header: "menus",
      Component: ({ param }) => param.menus?.length,
    },
    {
      header: "code",
      Component: ({ param }) => param.code,
    },
    {
      header: "averageDeliveryTime",
      Component: ({ param }) => param.averageDeliveryTime,
    },
    {
      header: "startTime",
      Component: ({ param }) => param.startTime || "-"
    },
    {
      header: "endTime",
      Component: ({ param }) => param.endTime || "-"
    },
    {
      header: "currentNotWorkingReason",
      Component: ({ param }) => param.currentNotWorkingReason || "-"
    },
    {
      header: "createdAt",
      Component: ({ param }) => isoToDate(param.createdAt),
    },
    {
      header: "Status",
      Component: ({ param }) => <TableStatus {...{ param, onClick, slice: { second: 5 } }} />,
    },
    {
      header: "Actions",
      Component: ({ param }) => <TableAction {...{ param, newRoute, onClick }} />,
    },
  ];
