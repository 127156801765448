import { useEffect } from "react";
import "./index.scss"
import bg from "assets/images/print-bg.png"

import Single from "./Single";
import { printData } from "./enum";

const PrintPage = ({ data = printData, design }) => {

    // function calculateNumberOfPages() {
    //     const content = document.getElementById('content');
    //     const pages = content.querySelectorAll('.print-page');
    //     return pages.length;
    // }

    // function setPrintPageSizes() {
    //     const totalPages = calculateNumberOfPages();
    //     const pageSize = totalPages > 0 ? 'A5' : '';
    //     document.styleSheets[0].cssRules[1].style.size = pageSize;
    // }

    // useEffect(() => {
    //     // setPrintPageSizes();
    // }, [])

    const pxToMm = (px) => {
        let div = document.createElement("div");
        div.style.display = "block";
        div.style.height = "1mm";
        document.body.appendChild(div);
        return Math.floor(px / div.offsetHeight);
        // return Math.floor(px / 0.2645833333);
        // return (px * 25.4) / DPI
    };

    const mmToPx = (mm) => {
        let div = document.createElement("div");
        div.style.display = "block";
        div.style.height = "1mm";
        document.body.appendChild(div);
        return Math.floor(mm * div.offsetHeight);
    };

    // const width = mmToPx(148.5)
    // const height = mmToPx(210)
    // const width = pxToMm(1241)
    // const height = pxToMm(1754)
    console.log("* * * PrintPage :", {
        data,
        //  width, height 
    });

    const GlobalStyler = () => (
        <style>
            {`
            body{
                margin:0
            }
                @media print {
                    @page {
                    //   size: 148mm 210mm;
                      size: A5 portrait;

                    }
                    .print-page {
                        page-break-after: always;
                    }
                }
                // #content{
                //     width: fit-content;
                // }
                .print-page{
                    width:148mm;
                    height:210mm;
                    background-image:url(${design?.background || bg});
                    background-position: center;
                    background-size: cover;
                    font-family: 'Barlow Semi Condensed', sans-serif;
                    position:relative;
                    display: flex;
                    padding:10px;
                    .base-canvas{
                        opacity:0;
                        position:absolute;
                    }
                    .first-box{
                        position: absolute;
                        color: white;
                        top: 70px;
                        left: 0;
                        right: 0;
                        margin: auto;
                        display: flex;
                        justify-content: space-between;
                        >div{
                            flex:1;
                            text-align:center;
                        }
                        .start{
                            img{
                                max-width: 215px;
                                max-height: 90px;
                            }
                        }
                        .end{
                            color:${design?.dark ? "#fff" : "#000"} ;
                            .top{
                                margin-right: 40px;
                                font-size: 20px;
                            }
                            .bottom{
                                margin-right: 30px;
                                margin-top: 10px;
                                font-size: 50px;
                            }
                        }
                    }

                    .second-box{
                        position: absolute;
                        color: white;
                        bottom: 208px;
                        width: 88%;
                        left: 0;
                        right: 0;
                        margin: auto;
                        display: flex;
                        justify-content: space-between;
                        >div{
                            flex:1;
                            text-align:center;
                        }
                        .start{
                            img{
                                max-width: 215px;
                                max-height: 90px;
                            }
                        }
                        .end{
                            position:relative;
                            .qrCode-roomNumber{
                                position: absolute;
                                width: 100%;
                                height: 100%;
                                color: #000;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                            }
                        }
                    }
                }

        `}
        </style>
    );





    return (
        <>
            {/* <div id="content"> */}
            {
                data?.map((x) => {
                    return (
                        <Single key={x.roomNumber} data={x} />
                    )
                })
            }
            {/* </div> */}
            <GlobalStyler />
        </>

    )
}

export default PrintPage;