import React, { Component, useEffect, useRef, useState } from "react";
import { Badge, Button, Dropdown } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import action from "redux/store/admin/action";
import { LANGUAGE_DATA, LANGUAGE_DATA_OBJECT, PLACEHOLDER_IMAGE } from "enumerations";
import { useTranslation } from "react-i18next";
import FieldSelect from "./FieldSelect";
import DropDownBootstrap from "./DropDownBootstrap";
import toastify from "lib/toastify";
import routes from "operations/routing/routes";
import soundMP3 from "assets/sound/simple-ringtone-84595.mp3";

const Navbar = () => {
	const currentAdmin = useSelector((state) => state.admin.data);
	const breadcrumbsRedux = useSelector((state) => state.breadcrumbs);
	const dispatch = useDispatch();
	const navigation = useNavigate();

	function toggleOffcanvas() {
		document.querySelector(".sidebar-offcanvas").classList.toggle("active");
	}
	function toggleRightSidebar() {
		document.querySelector(".right-sidebar").classList.toggle("open");
	}

	const handleLogOut = () => {
		dispatch(action.logout());
		navigation("/login");
	};

	const { t, i18n } = useTranslation();

	const changeLanguageHandler = (lang) => {
		i18n.changeLanguage(lang?.value);
	};

	useEffect(() => {
		if (i18n.language != LANGUAGE_DATA_OBJECT.en.value) changeLanguageHandler(LANGUAGE_DATA_OBJECT.en);

		// toastify.info(
		//   {
		//     title: <AlertComponent />,
		//     autoClose: false,
		//     closeOnClick: false,

		//   }
		// )
	}, [i18n.language]);
	//  ----------------------------------------- LOGS ----------------------------------------- //
	// console.log({ breadcrumbsRedux }, "bread");
	// console.log({ i18n });

	return (
		<nav className="navbar default-layout-navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row border-bottom">
			<div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
				<button
					className="navbar-toggler navbar-toggler d-none d-lg-flex  align-self-center ml-auto   "
					type="button"
					onClick={() => document.body.classList.toggle("sidebar-icon-only")}>
					<span className="mdi mdi-menu"></span>
				</button>

				<button className="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" onClick={toggleOffcanvas}>
					<span className="mdi mdi-menu"></span>
				</button>
				{/* <Link className="navbar-brand brand-logo" to="/"><img src={require('assets/images/logo.svg')} alt="logo" /></Link>
          <Link className="navbar-brand brand-logo-mini" to="/"><img src={require('assets/images/logo-mini.svg')} alt="logo" /></Link> */}
			</div>
			<div className="navbar-menu-wrapper d-flex align-items-stretch">
				<div className="search-field d-none d-md-block">
					<div className="d-flex align-items-center h-100">
						<nav aria-label="breadcrumb">
							<ol className="breadcrumb">
								{breadcrumbsRedux?.map((breadcrumb, index) =>
									breadcrumb.link ? (
										<li className="breadcrumb-item text-capitalize">
											<Link to={breadcrumb.link}>{breadcrumb.title}</Link>
										</li>
									) : (
										<li className="breadcrumb-item active text-capitalize" aria-current="page">
											{breadcrumb.title}
										</li>
									)
								)}
							</ol>
						</nav>
					</div>
					{/* <form className="d-flex align-items-center h-100" action="#">
              <div className="input-group">
                <div className="input-group-prepend bg-transparent">
                  <i className="input-group-text border-0 mdi mdi-magnify"></i>
                </div>
                <input type="text" className="form-control bg-transparent border-0" placeholder="Search projects"/>
              </div>
            </form> */}
				</div>
				<ul className="navbar-nav navbar-nav-right">
					{/* <li>
            <DropDownBootstrap
              data={LANGUAGE_DATA}
              click={changeLanguageHandler}
              value={i18n.language}
              title={LANGUAGE_DATA_OBJECT[i18n.language]?.label}
            />
          </li> */}

					{/* <li className="nav-item">
            <Dropdown alignLeft>
              <Dropdown.Toggle className="nav-link count-indicator">
                <i className="mdi mdi-bell-outline"></i>
                <span className="count-symbol bg-danger"></span>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu navbar-dropdown preview-list">
                <h6 className="p-3 mb-0">
                  <>Notifications</>
                </h6>
                <div className="dropdown-divider"></div>
                <Dropdown.Item className="dropdown-item preview-item" onClick={(evt) => evt.preventDefault()}>
                  <div className="preview-thumbnail">
                    <div className="preview-icon bg-success">
                      <i className="mdi mdi-calendar"></i>
                    </div>
                  </div>
                  <div className="preview-item-content d-flex align-items-start flex-column justify-content-center">
                    <h6 className="preview-subject font-weight-normal mb-1">
                      <>Event today</>
                    </h6>
                    <p className="text-gray ellipsis mb-0">
                      <>Just a reminder that you have an event today</>
                    </p>
                  </div>
                </Dropdown.Item>
                <div className="dropdown-divider"></div>
                <Dropdown.Item className="dropdown-item preview-item" onClick={(evt) => evt.preventDefault()}>
                  <div className="preview-thumbnail">
                    <div className="preview-icon bg-warning">
                      <i className="mdi mdi-settings"></i>
                    </div>
                  </div>
                  <div className="preview-item-content d-flex align-items-start flex-column justify-content-center">
                    <h6 className="preview-subject font-weight-normal mb-1">
                      <>Settings</>
                    </h6>
                    <p className="text-gray ellipsis mb-0">
                      <>Update dashboard</>
                    </p>
                  </div>
                </Dropdown.Item>
                <div className="dropdown-divider"></div>
                <Dropdown.Item className="dropdown-item preview-item" onClick={(evt) => evt.preventDefault()}>
                  <div className="preview-thumbnail">
                    <div className="preview-icon bg-info">
                      <i className="mdi mdi-link-variant"></i>
                    </div>
                  </div>
                  <div className="preview-item-content d-flex align-items-start flex-column justify-content-center">
                    <h6 className="preview-subject font-weight-normal mb-1">
                      <>Launch Admin</>
                    </h6>
                    <p className="text-gray ellipsis mb-0">
                      <>New admin wow</>!
                    </p>
                  </div>
                </Dropdown.Item>
                <div className="dropdown-divider"></div>
                <h6 className="p-3 mb-0 text-center cursor-pointer">
                  <>See all notifications</>
                </h6>
              </Dropdown.Menu>
            </Dropdown>
          </li> */}

					<li className="nav-item nav-profile">
						<Dropdown alignLeft>
							<Dropdown.Toggle className="nav-link">
								{/* <div className="nav-profile-img">
                  <img src={currentAdmin?.image || PLACEHOLDER_IMAGE} alt="user" />
                  <span className="availability-status online"></span>
                </div> */}
								<div className="nav-profile-text">{currentAdmin?.name + " " + currentAdmin?.lastName || currentAdmin?.email || "Account"}</div>
							</Dropdown.Toggle>

							<Dropdown.Menu className="navbar-dropdown">
								{/* <Dropdown.Item href="!#" onClick={(evt) => evt.preventDefault()}>
                  <i className="mdi mdi-cached mr-2 text-success"></i>
                  Activity Log
                </Dropdown.Item> */}
								<Dropdown.Item onClick={handleLogOut}>
									<i className="mdi mdi-logout mr-2 text-primary"></i>
									sign out
								</Dropdown.Item>
							</Dropdown.Menu>
						</Dropdown>
					</li>

					{/* <li className="nav-item">
            <Dropdown alignRight>
              <Dropdown.Toggle className="nav-link count-indicator">
                <i className="mdi mdi-email-outline"></i>
                <span className="count-symbol bg-warning"></span>
              </Dropdown.Toggle>

              <Dropdown.Menu className="preview-list navbar-dropdown">
                <h6 className="p-3 mb-0"><>Messages</></h6>
                <div className="dropdown-divider"></div>
                <Dropdown.Item className="dropdown-item preview-item" onClick={evt => evt.preventDefault()}>
                  <div className="preview-thumbnail">
                    <img src={require("assets/images/faces/face4.jpg")} alt="user" className="profile-pic" />
                  </div>
                  <div className="preview-item-content d-flex align-items-start flex-column justify-content-center">
                    <h6 className="preview-subject ellipsis mb-1 font-weight-normal"><>Mark send you a message</></h6>
                    <p className="text-gray mb-0">
                      1 <>Minutes ago</>
                    </p>
                  </div>
                </Dropdown.Item>
                <div className="dropdown-divider"></div>
                <Dropdown.Item className="dropdown-item preview-item" onClick={evt => evt.preventDefault()}>
                  <div className="preview-thumbnail">
                    <img src={require("assets/images/faces/face2.jpg")} alt="user" className="profile-pic" />
                  </div>
                  <div className="preview-item-content d-flex align-items-start flex-column justify-content-center">
                    <h6 className="preview-subject ellipsis mb-1 font-weight-normal"><>Cregh send you a message</></h6>
                    <p className="text-gray mb-0">
                      15 <>Minutes ago</>
                    </p>
                  </div>
                </Dropdown.Item>
                <div className="dropdown-divider"></div>
                <Dropdown.Item className="dropdown-item preview-item" onClick={evt => evt.preventDefault()}>
                  <div className="preview-thumbnail">
                    <img src={require("assets/images/faces/face3.jpg")} alt="user" className="profile-pic" />
                  </div>
                  <div className="preview-item-content d-flex align-items-start flex-column justify-content-center">
                    <h6 className="preview-subject ellipsis mb-1 font-weight-normal"><>Profile picture updated</></h6>
                    <p className="text-gray mb-0">
                      18 <>Minutes ago</>
                    </p>
                  </div>
                </Dropdown.Item>
                <div className="dropdown-divider"></div>
                <h6 className="p-3 mb-0 text-center cursor-pointer">4 <>new messages</></h6>
              </Dropdown.Menu>
            </Dropdown>
          </li> */}

					{/* <li className="nav-item nav-logout d-none d-lg-block">
            <a className="nav-link" href="!#" onClick={event => event.preventDefault()}>
              <i className="mdi mdi-power"></i>
            </a>
          </li> */}
					{/* <li className="nav-item nav-settings d-none d-lg-block">
            <button type="button" className="nav-link border-0" onClick={toggleRightSidebar} >
              <i className="mdi mdi-format-line-spacing"></i>
            </button>
          </li> */}
				</ul>
				{/* <button
          className="navbar-toggler navbar-toggler-right d-lg-none align-self-center"
          type="button"
          onClick={toggleOffcanvas}
        >
          <span className="mdi mdi-menu"></span>
        </button> */}
			</div>
		</nav>
	);
};

export default Navbar;
