import celery from "assets/images/allergens/celery/PNG/celery_amber_100x100.png";
import crustaceans from "assets/images/allergens/crustaceans/PGN/crustaceans_amber_100x100.png";
import eggs from "assets/images/allergens/eggs/PNG/eggs_amber_100x100.png";
import fish from "assets/images/allergens/fish/PNG/fish_amber_100x100.png";
import lupin from "assets/images/allergens/lupin/PNG/lupin_amber_100x100.png";
import milk from "assets/images/allergens/milk/PNG/milk_amber_100x100.png";
import molluscs from "assets/images/allergens/molluscs/PNG/molluscs_amber_100x100.png";
import mustard from "assets/images/allergens/mustard/PNG/mustard_amber_100x100.png";
import peanut from "assets/images/allergens/peanut/PNG/peanut_amber_100x100.png";
import sesame from "assets/images/allergens/sesame/PNG/sesame_amber_100x100.png";
import soya from "assets/images/allergens/soya/PNG/soya_amber_100x100.png";
import sulphurdioxide from "assets/images/allergens/sulphurdioxide/PNG/sulphurdioxide_amber_100x100.png";
import treenut from "assets/images/allergens/treenut/PNG/treenut_amber_100x100.png";
import wheat from "assets/images/allergens/wheat/PNG/wheat_amber_100x100.png";

export const API_NAME = "product";
export const ROUTE_NAME = "product";
export const COMPONENT_NAMES = ["product", "products"];
export const ALLERGENS = {
    celery: {
        label: "celery",
        value: "celery",
        icon: celery
    },
    crustaceans: {
        label: "crustaceans",
        value: "crustaceans",
        icon: crustaceans
    },
    eggs: {
        label: "eggs",
        value: "eggs",
        icon: eggs
    },
    fish: {
        label: "fish",
        value: "fish",
        icon: fish
    },
    lupin: {
        label: "lupin",
        value: "lupin",
        icon: lupin
    },

    milk: {
        label: "milk",
        value: "milk",
        icon: milk
    },
    molluscs: {
        label: "molluscs",
        value: "molluscs",
        icon: molluscs
    },
    mustard: {
        label: "mustard",
        value: "mustard",
        icon: mustard
    },
    peanut: {
        label: "peanut",
        value: "peanut",
        icon: peanut
    },
    sesame: {
        label: "sesame",
        value: "sesame",
        icon: sesame
    },
    soya: {
        label: "soya",
        value: "soya",
        icon: soya
    },
    sulphurdioxide: {
        label: "sulphurdioxide",
        value: "sulphurdioxide",
        icon: sulphurdioxide
    },
    treenut: {
        label: "treenut",
        value: "treenut",
        icon: treenut
    },
    wheat: {
        label: "wheat",
        value: "wheat",
        icon: wheat
    },
}

export const ALLERGENS_ARRAY = Object.values(ALLERGENS)