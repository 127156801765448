import styles from "./GalleryComponent.module.scss";
import placeholder from "assets/images/placeholder.png";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import compareTranslate from "utils/compareTranslate";
const GalleryComponent = ({ description, selectedPhoto, handleModal, label, isRequired, Controller, name, control, errors, register, disables }) => {
  // const [state, setState] = useState(null);
  let newRegister = register ? register(name) : {};
  const [t] = useTranslation();

  return (
    <div className={styles.galleryComponent}>
      <div className={styles.titleWrapper}>
        <label for="image">
          {compareTranslate(t, label || "image")}
          {isRequired && " *"}
          {description ? <small>
            {description}
          </small> : ""}
        </label>
        {handleModal ? <button type={"button"} className={`${styles.button} btn btn-success btn-sm btn btn-primary`} onClick={handleModal}>
          <i className="mdi mdi-plus" />
        </button> : ""}
      </div>
      {errors && <p className={"error-text mt-1"}>{!disables?.translate?.error ? compareTranslate(t, errors?.[name]?.message) : errors?.[name]?.message}</p>}
      <Controller
        name={name}
        control={control}
        register={newRegister}
        render={({ field: { value } }) => {
          return (
            <div className={styles.thumbnailWrapper}>
              <img src={value?.path || value?.src || selectedPhoto?.path || selectedPhoto?.src || value || placeholder} alt="thumbnail photo" />
            </div>
          );
        }}
      />
    </div>
  );
};

export default GalleryComponent;
