import React from "react";
import { Form } from "react-bootstrap";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import compareTranslate from "utils/compareTranslate";

const CheckBox = ({ control, name, label, valid, register, errors, require, type, error, onChange, onClick, disables, ...other }) => {

  let newRegister = register ? register(name) : {};
  const [t] = useTranslation();

  // console.log({ other }, name);
  return (
    <Form.Group className="form-group ">
      <div className="input-wrapper">
        <div className="form-check">
          <label className="form-check-label">
            {control ? <Controller
              control={control}
              ref={newRegister}
              name={name}

              render={({ field: { onChange: change, value, ...prop } }) => {
                console.log({ value }, name);

                function handleChange(params) {
                  // console.log({ params, value }, params.target.checked);
                  change?.(params);
                  onChange?.(params);
                }
                return <input type="checkbox" className="form-check-input" value={value} checked={value} defaultChecked={value} onChange={handleChange} {...prop} {...other} />
                // console.log({ onChange, value, options },'');
                // return <Select className="custom-selector" placeholder={label} options={newOptions} value={value} onChange={handleChange} {...other} />;
              }}
            />
              :
              <input name={name} type="checkbox" className="form-check-input"
                defaultChecked={other.value}
                {...other} {...newRegister}
                onClick={(e) => onClick?.({ name, value: !other.value })}
              />}

            <i className="input-helper"></i>
            {label ? (
              <label htmlFor={name}>
                {!disables?.translate?.label ? compareTranslate(t, label) : label}
                {require ? " *" : ""}
              </label>
            ) : (
              ""
            )}
          </label>
        </div>
      </div>
      <div className={`error-text mt-1 `}>{!disables?.translate?.error ? compareTranslate(t, errors?.[name]?.message) : errors?.[name]?.message}</div>
    </Form.Group>
  );
};
export default CheckBox;
