import bg from "assets/images/print-bg.png"

export const printData = [
    {
        logo: "https://1000logos.net/wp-content/uploads/2016/10/Amazon-Logo-1997-500x281.png",
        uuid: "019B07E0-5292-48D5-BA59-3F6176650829",
        background: bg
    },
    {
        logo: "https://1000logos.net/wp-content/uploads/2016/10/Amazon-Logo-1997-500x281.png",
        roomNumber: "2010 sdf sd61",
        uuid: "019B07E0-5292-48D5-BA59-3F6176650829",
        background: bg

    },
    {
        logo: "https://1000logos.net/wp-content/uploads/2016/10/Amazon-Logo-1997-500x281.png",
        roomNumber: "MET 576",
        uuid: "019B07E0-5292-48D5-BA59-3F6176650829",
        background: bg

    },
    {
        logo: "https://1000logos.net/wp-content/uploads/2016/10/Amazon-Logo-1997-500x281.png",
        roomNumber: 203000,
        uuid: "019B07E0-5292-48D5-BA59-3F6176650829",
        background: bg

    },
    {
        logo: "https://1000logos.net/wp-content/uploads/2016/10/Amazon-Logo-1997-500x281.png",
        roomNumber: 21,
        uuid: "019B07E0-5292-48D5-BA59-3F6176650829",
        background: bg

    },
]
