import { STATUSES } from "enumerations";
import { Children } from "react";
import { Dropdown } from "react-bootstrap";

const Status = ({ param, onClick, slice }) => {
  let foundStatus = STATUSES.find((status) => status.value === param?.isActive);
  let status = foundStatus?.value;
  return (
    <Dropdown>
      <Dropdown.Toggle
        id="dropdown-basic"
        className="btn-sm filter-sort mr-3"
        variant={`${status ? "success" : "danger"
          // ?
          //   // "secondary"
          //   "danger"
          //   : status === "ACTIVE" ? "success" : status === "DELETE" ? "danger" : status === "BLOCK" ? "warning" : "dark"
          }
        `}
      >
        <span>{foundStatus?.label}</span>
      </Dropdown.Toggle>

      <Dropdown.Menu className="dropDown-menu-custom">
        {Children.toArray(
          STATUSES.slice(slice?.first || 0, slice?.second || 3).map((item) => (
            <Dropdown.Item
              key={item.label + "_STATUS"}
              onClick={item.value === param?.isActive ? "" : () => onClick({ data: [param], status: item })}
              className="dropDown-item-custom"
              active={item.value === param?.isActive
              }
            >
              {item?.label}
            </Dropdown.Item>
          ))
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default Status;
